import * as React from "react";
import styled from "styled-components";
import LocationIcon from "../../../assets/images/location_white_icon.svg";
import { Link } from "../../shared/Link";
import { get } from "lodash";
import { toREM, toVWDesktop } from "../../../helpers/styles";
import * as CommonHeaderTypes from "../types";
import { useEffect, useState } from "react";

export type DrawerProps = {
  className?: string;
  subMenuItem: CommonHeaderTypes.SubMenuItemWithOptions;
};

const DrawerSubMenuItemView = ({ className, subMenuItem }: DrawerProps) => {
  const [selectedItem, setSelectedItem] = useState<
    CommonHeaderTypes.Item | undefined
  >();

  useEffect(() => {
    setSelectedItem(get(subMenuItem, "options[0].items[0]", undefined));
  }, [subMenuItem]);

  return (
    <Container className={className}>
      <Content>
        {subMenuItem?.options?.map((option, i, options) => (
          <>
            <GroupTitle>{option.name}</GroupTitle>
            {option?.items?.map((item, index) => (
              <ItemNameWrapper key={index}>
                <ItemName
                  to={item?.menuItemLink}
                  active={item?.name === selectedItem?.name}
                  onClick={() => setSelectedItem(item)}
                  onMouseEnter={() => setSelectedItem(item)}
                >
                  {item.name}
                </ItemName>
              </ItemNameWrapper>
            ))}
            {i < options.length - 1 && <Spacer />}
          </>
        ))}
      </Content>
      <Content>
        {selectedItem && (
          <ItemContainer>
            <ItemImage src={selectedItem?.image} alt={selectedItem?.imageAlt} />
            <ItemActions>
              <PrimaryButton to={selectedItem?.primaryButtonLink || ""}>
                {selectedItem?.primaryButtonLabel}
              </PrimaryButton>
              <SecondaryButton to={selectedItem?.secondaryButtonLink || ""}>
                <Icon
                  src={LocationIcon}
                  alt={selectedItem?.secondaryButtonLabel}
                />
                {selectedItem?.secondaryButtonLabel}
              </SecondaryButton>
            </ItemActions>
          </ItemContainer>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  flex: 1 0 50%;
`;

const GroupTitle = styled.div<{ noPaddingTop?: boolean; active?: boolean }>`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  line-height: ${toREM(31)}rem;
  font-weight: 900;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: fit-content;
  margin-bottom: ${toVWDesktop(7)}vw;
`;

const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemName = styled(Link)<{ noPaddingTop?: boolean; active?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.active ? "#75C154" : "#ffffff")};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  line-height: ${toREM(24)}rem;
  font-weight: 800;
  letter-spacing: 0.06em;
  width: fit-content;
  text-decoration: none;
  top: ${toVWDesktop(7)}vw;
  margin-top: ${toVWDesktop(7)}vw;
  margin-left: ${toVWDesktop(7)}vw;
  margin-bottom: ${toVWDesktop(7)}vw;
`;

const Spacer = styled.div`
  height: ${toVWDesktop(31)}vw;
`;

const ItemContainer = styled.div`
  width: ${toVWDesktop(290)}vw;
  display: flex;
  flex-direction: column;
`;

const ItemImage = styled.img`
  object-fit: contain;
  height: ${toVWDesktop(240)}vw;
  width: auto;
`;

const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${toVWDesktop(10)}vw;
`;

const PrimaryButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1 0 ${toREM(150)}rem;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  line-height: ${toREM(21)}rem;
  font-weight: 800;
  letter-spacing: 0.01em;
  border: 3px solid #ffffff;
  padding: ${toVWDesktop(10)}vw ${toVWDesktop(29)}vw;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  height: fit-content;
`;

const SecondaryButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  line-height: ${toREM(18)}rem;
  font-weight: 800;
  letter-spacing: 0;
  border: none;
  padding: ${(props) =>
    props.theme.isRTL
      ? `${toVWDesktop(10)}vw ${toVWDesktop(17)}vw ${toVWDesktop(
          10
        )}vw ${toVWDesktop(0)}vw`
      : `${toVWDesktop(10)}vw ${toVWDesktop(0)}vw ${toVWDesktop(
          10
        )}vw ${toVWDesktop(17)}vw`};
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  height: fit-content;
`;

const Icon = styled.img`
  object-fit: contain;
  width: ${toVWDesktop(18.62)}vw;
  margin: ${(props) =>
    props.theme.isRTL
      ? `0 0 0 ${toVWDesktop(8.6)}vw`
      : `0 ${toVWDesktop(8.6)}vw 0 0`};
  height: auto;
`;

export default DrawerSubMenuItemView;
