
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
query {
  directus {
    language_selector {
      flag_image {
        id
        filename_disk
      }
      name
      locale {
        code
        enabled
      }
      translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
        languages_code {
          code
          enabled
        }
        language_label
      }
    }
  }
}

`;

  export const useLanguagePickerStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  