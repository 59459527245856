export const getQueryParams = (): { [key: string]: string } => {
  if (typeof window !== "undefined") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    const objParams = Object.fromEntries(entries);

    return objParams;
  }
  return null;
};

export const removeQueryParamsFromUrl = () => {
  try {
    window.history.replaceState({}, document.title, window.location.pathname);
  } catch (e) {
    console.log(e);
  }
};
