
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
query {
  directus {
    pack_page {
      pack_page_slug
      translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
        languages_code {
          code
          name
        }
        status
        pack_content_status
        pack_content_pack {
          translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
            languages_code {
              code
              name
            }
            status
            variant_list {
              id
              pack_variant_id {
                variant_slug
                translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                  languages_code {
                    code
                    name
                  }
                  status
                  title
                  shoppable_product {
                    translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                      languages_code {
                        code
                        name
                      }
                      id
                      status
                      product_number_id
                      variant_sku
                      product_title
                      variant_title
                      small_description
                      large_description
                      small_image_alt
                      small_image {
                        id
                        filename_disk
                      }
                      large_image_alt
                      large_image {
                        id
                        filename_disk
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

  export const usePacksStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  