import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { BREAKPOINTS } from "../../../helpers/theme";
import { toVWDesktop } from "../../../helpers/styles";
import * as CommonHeaderTypes from "../types";
import DrawerSubMenuItem from "./DrawerSubMenuItem";
import DrawerSubMenuItemView from "./DrawerSubMenuItemView";

const ROOT_ELEMENT_ID = {
  LARGE: "menu-desktop-large-drawer",
  SMALL: "menu-desktop-small-drawer",
};

export type DrawerProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  selectedMenuItem: CommonHeaderTypes.MenuItem | undefined;
};

const Drawer = ({
  className,
  selectedMenuItem,
  open,
  onClose,
}: DrawerProps) => {
  const styles = useSpring({ opacity: open ? 1 : 0 });

  const subMenuItems: Array<CommonHeaderTypes.SubMenuItem> = useMemo(() => {
    return selectedMenuItem?.subMenuItems || [];
  }, [selectedMenuItem]);

  const [selectedSubMenuItem, setSelectedSubMenuItem] =
    useState<CommonHeaderTypes.SubMenuItem>();

  const shouldRenderFullView = useCallback(() => {
    if (!subMenuItems) {
      return false;
    }
    return (
      subMenuItems.filter((subMenuItem) => subMenuItem?.options?.length > 0)
        .length > 0
    );
  }, [subMenuItems]);

  const getDrawerPosition = useCallback(() => {
    try {
      const elements = document.querySelectorAll(".menu-item");
      const element = (Array.from(elements) || []).filter(
        (e) =>
          selectedMenuItem && e?.textContent?.includes(selectedMenuItem.label)
      )[0];
      return element.getBoundingClientRect();
    } catch (e) {
      return null;
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    if (shouldRenderFullView()) {
      setSelectedSubMenuItem(subMenuItems[0]);
    }
  }, [subMenuItems, shouldRenderFullView]);

  if (!subMenuItems || subMenuItems.length === 0) {
    return null;
  }

  if (!shouldRenderFullView()) {
    const drawerPosition = getDrawerPosition();
    return (
      <SmallContainer
        id={ROOT_ELEMENT_ID.SMALL}
        className={className}
        style={styles}
        left={`calc(${drawerPosition?.left}px - ${toVWDesktop(40)}vw)`}
      >
        {subMenuItems.map((item, i) => (
          <>
            <StyledDrawerSubMenuItemView
              subMenuItem={item}
              active={selectedSubMenuItem?.label === item.label}
              noMarginTop={i === 0}
              onClick={(subMenuItem) => setSelectedSubMenuItem(subMenuItem)}
            />
          </>
        ))}
      </SmallContainer>
    );
  }

  return (
    <Container id={ROOT_ELEMENT_ID.LARGE} className={className} style={styles}>
      <LeftContent>
        <div>
          {subMenuItems.map((item, i) => (
            <>
              <StyledDrawerSubMenuItemView
                subMenuItem={item}
                active={selectedSubMenuItem?.label === item.label}
                noMarginTop={i === 0}
                onClick={(subMenuItem) => setSelectedSubMenuItem(subMenuItem)}
              />
            </>
          ))}
        </div>
      </LeftContent>
      <RightContent>
        <DrawerSubMenuItemView
          subMenuItem={
            selectedSubMenuItem as CommonHeaderTypes.SubMenuItemWithOptions
          }
        />
      </RightContent>
    </Container>
  );
};

const Container = styled(animated.div)`
  display: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    position: absolute;
    width: 100%;
  }
`;

const SmallContainer = styled(animated.div)<{ left: string }>`
  padding: ${toVWDesktop(40)}vw;
  background-color: #ffffff;
  left: ${(props) => props.left};
  display: flex;
  flex-direction: column;
`;

const LeftContent = styled.div`
  width: 37%;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  padding: ${toVWDesktop(38)}vw ${toVWDesktop(65)}vw;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${(props) =>
      props.theme.isRTL
        ? `0 0 0 ${toVWDesktop(35)}vw`
        : `0 ${toVWDesktop(35)}vw 0 0`};
  }
`;

const RightContent = styled.div`
  width: 63%;
  background-color: #012102;
  padding: ${toVWDesktop(38)}vw ${toVWDesktop(65)}vw;
`;

const StyledDrawerSubMenuItemView = styled(DrawerSubMenuItem)<{
  noMarginTop?: boolean;
}>`
  margin-top: ${(props) => toVWDesktop(props.noMarginTop ? 0 : 7)}vw;
`;

export default Drawer;
