import * as React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Link } from "../../shared/Link";
import { BREAKPOINTS } from "../../../helpers/theme";
import { toREM, toVWDesktop } from "../../../helpers/styles";
import * as CommonHeaderTypes from "../types";

export type MenuItemProps = {
  className?: string;
  onClick: (mItem: CommonHeaderTypes.MenuItem) => void;
  onMouseEnter?: (mItem: CommonHeaderTypes.MenuItem) => void;
  onMouseLeave?: (mItem: CommonHeaderTypes.MenuItem) => void;
  menuItem: CommonHeaderTypes.MenuItem;
  active: boolean;
  fontColor: string;
};

const MenuItem = ({
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  active,
  menuItem,
  fontColor,
}: MenuItemProps) => {
  const styles = useSpring({ width: active ? "100%" : "0%" });
  return (
    <StyledLink
      className={`${className} menu-item`}
      active={active}
      fontColor={fontColor}
      onClick={() => onClick(menuItem)}
      onMouseEnter={() => onMouseEnter && onMouseEnter(menuItem)}
      to={menuItem?.link || undefined}
    >
      {menuItem.label}
      <Bar style={styles} className={"menu-bar"} />
    </StyledLink>
  );
};

const StyledLink = styled(Link)<{ fontColor: string; active: boolean }>`
  display: none;
  background: inherit;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-weight: ${(props) => (props.active ? 900 : 800)};
    font-size: ${toREM(14)}rem;
    line-height: ${toREM(18)}rem;
    letter-spacing: 0.045em;
    color: ${(props) => props.fontColor};
    text-transform: uppercase;
    margin: ${(props) =>
      props.theme.isRTL
        ? `0 ${toVWDesktop(38)}vw 0 0`
        : `0 0 0 ${toVWDesktop(38)}vw`};
    text-decoration: none;
  }
`;

const Bar = styled(animated.div)`
  width: 0;
  height: 3px;
  background-color: #2b6447;
`;

export default MenuItem;
