
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      settings {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          shopify
          recipes_main_tab
          languages_code {
            name
            code
          }
        }
      } 
    }
  }
`;

  export const useSettingsStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  