import { useIntl } from "./useIntl";
import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import * as CommonHeaderTypes from "../components/Header/types";
import { getAssetURL } from "../helpers/assets";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { isPublished } from "../helpers/directus";
import { useHeaderStaticQuery } from "../hooks/staticQueries/useHeaderStaticQuery";

type HeaderData = {
  menuItems: Array<CommonHeaderTypes.MenuItem>;
} | null;
export const useHeaderData = (): HeaderData => {
  const intl = useIntl();
  const data = useHeaderStaticQuery();
  const labels = useStaticLabels();

  const headerData = useMemo<HeaderData>((): HeaderData => {
    const translatedData = getTranslations(
      data,
      "directus.header.translations",
      intl.locale
    );

    if (!isPublished(translatedData?.status)) return null;

    return {
      menuItems: translatedData?.menu_items?.map((mItem: any) => {
        const translatedMItem = getTranslations(
          mItem,
          "header_menu_item_id.translations",
          intl.locale
        );

        return {
          label: translatedMItem?.label,
          link: translatedMItem?.link,
          subMenuItems: translatedMItem?.sub_menu_items?.map(
            (sMenuItem: any) => {
              const translatedSMenuItem = getTranslations(
                sMenuItem,
                "header_sub_menu_item_id.translations",
                intl.locale
              );

              return {
                label: translatedSMenuItem?.label,
                link: translatedSMenuItem?.link,
                options: translatedSMenuItem?.options?.map((option: any) => {
                  const translatedOption = getTranslations(
                    option,
                    "header_item_group_id.translations",
                    intl.locale
                  );

                  return {
                    name: translatedOption?.name,
                    items: translatedOption?.items?.map((item: any) => {
                      const translatedItem = getTranslations(
                        item,
                        "header_item_id.translations",
                        intl.locale
                      );

                      return {
                        image: getAssetURL(translatedItem?.image),
                        imageAlt: translatedItem?.image_alt,
                        name: translatedItem?.name,
                        primaryButtonLabel: labels?.viewNow,
                        primaryButtonLink: translatedItem?.primary_button_link,
                        secondaryButtonLabel: labels?.findNearMe,
                        secondaryButtonLink:
                          translatedItem?.secondary_button_link,
                        secondaryButtonIcon: getAssetURL(
                          translatedItem?.secondary_button_icon
                        ),
                        menuItemLink: translatedItem?.menu_item_link,
                      };
                    }),
                  };
                }),
              };
            }
          ),
        };
      }),
    };
  }, [data, intl]);

  return headerData;
};
