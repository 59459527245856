
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      mailing_list {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          id
          success_message
          disclaimer
          description
          languages_code {
            code
            name
          }
          main_title
          button_label
          enable_checkbox
          input_placeholder
          klaviyo_list_id
          status
        }
      }
    }
  }
`;

  export const useMailingStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  