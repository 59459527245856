import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import TriangleIcon from "../../assets/images/triangle_icon.svg";
import { vwMobile, vwDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { openLanguagePickerModal } from "../../store/modals";
import { useLanguagePickerStaticQuery } from "../../hooks/staticQueries/useLanguagePickerStaticQuery";
import { useIntl } from "../../hooks/useIntl";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { getTranslations } from "../../helpers/translations";
import { getAssetURL } from "../../helpers/assets";

const LanguagePicker: FC = () => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const languagePickerData = useLanguagePickerStaticQuery();
  const [currentLanguage, setCurrentLanguage] =
    useState<{ flag: string | undefined; label: string }>();
  const dispatch = useDispatch();
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(openLanguagePickerModal());
  };

  useEffect(() => {
    const {
      directus: { language_selector },
    } = languagePickerData;
    const activeLanguagePickerOption = language_selector
      ?.filter((languagePicker: any) => languagePicker?.locale?.enabled)
      .find(
        (languagePicker: any) => languagePicker?.locale?.code === intl?.locale
      );
    const activeLanguageTranslations = getTranslations(
      activeLanguagePickerOption,
      "translations",
      intl.locale
    );
    setCurrentLanguage({
      label: activeLanguageTranslations?.language_label,
      flag: getAssetURL(activeLanguagePickerOption?.flag_image),
    });
  }, [intl?.locale, languagePickerData]);

  return (
    <Container>
      <Label>{labels?.languageSelectorDropdown}</Label>
      <Button onClick={handleClick} aria-label={currentLanguage?.label}>
        <Flag src={currentLanguage?.flag} alt={currentLanguage?.label} />
        <SelectedLanguage>{currentLanguage?.label}</SelectedLanguage>
        <Triangle src={TriangleIcon} alt={"triangle"} />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${vwMobile(12)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(12)};
    width: ${vwDesktop(190)};
  }
`;

const Label = styled.div`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  font-size: ${toREM(16)}rem;
  letter-spacing: 0;
  line-height: ${toREM(21)}rem;
`;

const Button = styled.button`
  position: relative;
  border: none;
  width: 100%;
  border-bottom: 2px solid #000000;
  background: #ffffff;
  display: flex;
  cursor: pointer;
  gap: ${vwMobile(10)};
  padding-bottom: ${vwMobile(6)};
  align-items: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(10)};
    padding-bottom: ${vwDesktop(6)};
  }
`;

const SelectedLanguage = styled.div`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  font-size: ${toREM(15)}rem;
  letter-spacing: 0;
  line-height: ${toREM(21)}rem;
`;

const Flag = styled.img`
  width: ${vwMobile(20)};
  height: auto;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(20)};
    height: auto;
    object-fit: contain;
  }
`;

const Triangle = styled.img`
  position: absolute;
  right: ${vwMobile(1)};
  transform: rotate(90deg);
  width: ${vwMobile(4.5)};
  height: auto;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    right: ${vwDesktop(1)};
    width: ${vwDesktop(4.5)};
    height: auto;
    object-fit: contain;
  }
`;

export default LanguagePicker;
