import { useCallback, useEffect, useMemo } from "react";
import { ShopifyService } from "../services/shopify/shopify";
import { setCustomer } from "../store/shopify";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export const useShopifyCustomer = () => {
  const dispatch = useAppDispatch();
  const shopifyCustomer = useAppSelector(
    (state) => state.shopify.customer,
    (a, b) =>
      a?.accessToken?.accessToken === b?.accessToken?.accessToken &&
      a?.addresses?.edges?.length === b?.addresses?.edges?.length &&
      a?.id === b?.id
  );

  const fetchCustomer = useCallback(async () => {
    try {
      if (shopifyCustomer?.accessToken) {
        const customer = await ShopifyService.getCustomer(
          shopifyCustomer?.accessToken?.accessToken
        );
        if (!customer) {
          dispatch(setCustomer(null));
          return;
        }
        dispatch(
          setCustomer({
            ...customer,
            accessToken: shopifyCustomer?.accessToken,
          })
        );
      }
    } catch (e) {
      dispatch(setCustomer(null));
      console.log(e);
    }
  }, [shopifyCustomer?.accessToken?.accessToken]);

  const login = async (email: string, password: string) => {
    const data = await ShopifyService.customerAccessTokenCreate(
      email,
      password
    );

    const customer = await ShopifyService.getCustomer(
      data?.customerAccessToken?.accessToken
    );
    dispatch(
      setCustomer({
        ...customer,
        accessToken: data?.customerAccessToken,
      })
    );
    return data;
  };

  const reset = async (password: string, resetUrl: string) => {
    const data = await ShopifyService.customerReset(password, resetUrl);
    const customer = await ShopifyService.getCustomer(
      data?.customerAccessToken?.accessToken ?? ""
    );

    dispatch(
      setCustomer({
        ...customer,
        accessToken: data?.customerAccessToken,
      })
    );
    return data;
  };

  const register = async (
    email = "",
    password = "",
    firstName = "",
    lastName = ""
  ) => {
    const data = await ShopifyService.createAccount(
      email,
      password,
      firstName,
      lastName
    );

    if (data?.customer)
      dispatch(
        setCustomer({
          email,
          password,
          firstName,
          lastName,
        })
      );

    return data;
  };

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  const obj = useMemo(() => {
    return {
      shopifyCustomer,
      register,
      login,
      reset,
    };
  }, [shopifyCustomer]);

  return obj;
};
