import { graphql, useStaticQuery } from "gatsby";
export const query = graphql`
  query {
    directus {
      languages {
        code
        font_family
      }
    }
  }
`;

export const useFontFamilyStaticQuery = () => {
  const data = useStaticQuery(query);
  return data;
};
