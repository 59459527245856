import { uniqueId } from "lodash";
import React, { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { toREM, vwDesktop, vwMobile } from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import ErrorMessage, {
  COLOR_ON_DARK,
  COLOR_ON_LIGHT,
} from "../ErrorMessage/ErrorMessage";

export type InputProps = {
  label: string;
  value: string;
  type?: string;
  error?: string;
  bgType?: "light" | "dark";
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const bgMap = {
  light: COLOR_ON_LIGHT,
  dark: COLOR_ON_DARK,
};

export default function Input({
  label = "",
  value = "",
  className = "",
  error = "",
  bgType = "dark",
  ...rest
}: InputProps): ReactElement {
  const id = useRef<string>(uniqueId("text_input_"));
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>();

  return (
    <Container>
      <Box
        className={className}
        onClick={() => inputRef.current?.focus()}
        hasErrors={!!error}
        bgType={bgType}
      >
        {label && (
          <Label
            htmlFor={rest?.id || id?.current}
            className={value?.length > 0 || focused ? "up" : ""}
          >
            {label}
          </Label>
        )}
        <input
          id={rest?.id || id?.current}
          ref={(r) => (inputRef.current = r)}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          aria-label={label}
          {...rest}
        />
      </Box>
      {error && <ErrorMessage message={error} bgType={bgType} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-width: ${vwMobile(150)};
  ${MEDIA_DESKTOP} {
    min-width: ${vwDesktop(150)};
  }
`;

const Box = styled.div<{ hasErrors: boolean; bgType: string }>`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;

  display: flex;
  position: relative;
  cursor: text;

  width: 100%;
  height: ${vwMobile(56)};
  padding-inline: ${vwMobile(20)};

  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #ffffff;
  border: ${vwMobile(2)} solid
    ${(p) => (p.hasErrors ? bgMap[p.bgType] : "#000000")};

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(56)};
    padding-inline: ${vwDesktop(20)};
    border-width: ${vwDesktop(2)};
  }

  input {
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    border: none;
    outline: none;
    width: 100%;
    flex: 1;
    align-self: flex-end;
    position: relative;
    bottom: ${vwMobile(5)};
    background: inherit;
    z-index: 1;
    color: #000000;

    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(16)}rem;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      bottom: ${vwDesktop(5)};
    }
  }
`;
const Label = styled.label`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${toREM(16)}rem;
  color: #000000;
  font-weight: 700;
  z-index: 2;
  line-height: 1;
  transition: all 0.2s;

  &.up {
    font-size: ${toREM(12)}rem;
    transform: translateY(-150%);
  }
`;
