
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      cart {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          id
          languages_code {
            code
            name
          }
          title
          checkout_label
          remove_button_label
          empty_cart_label
          shop_our_products_label
          status
        }
      }
    }
  }
`;

  export const useCartStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  