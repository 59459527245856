/**
 * Due to Gatsby being SSR, sometimes it is needed a way to trigger a
 * rerender of a component when it loads on the client side. This
 * hooks will let you know what is the current environment server/client
 * and trigger a rerender with it.
 */
import { useEffect, useState } from "react";

export enum ENV {
  CLIENT = "CLIENT",
  SERVER = "SERVER",
}

export const useEnv = (): Array<ENV> => {
  const [env, setEnv] = useState(ENV.SERVER);

  useEffect(() => {
    setEnv(ENV.CLIENT);
  }, []);

  return [env];
};

export default useEnv;
