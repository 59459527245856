import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "../Header";
import Footer from "../Footer";
import MailingList from "../MailingList";
import Cookies from "../Cookies";
import useRightToLeft from "../../hooks/useRightToLeft";
import SignUpModal from "../SignUpModal/SignUpModal";
import LanguagePickerModal from "../LanguagePickerModal";
import { FONTS } from "../../helpers/theme";
import { useFontFamily } from "../../hooks/useFontFamily";
import Userway from "../Userway";

export type PageProps = {
  children: React.ReactNode;
  helmet: React.ReactNode;
  headerBackgroundColor: string;
  displayHeader?: boolean;
  displayFooter?: boolean;
  displayMailing?: boolean;
};

const Page = ({
  children,
  helmet,
  headerBackgroundColor,
  displayHeader = true,
  displayFooter = true,
  displayMailing = true,
}: PageProps) => {
  const isRTL = useRightToLeft();
  const fontFamily = useFontFamily();

  const theme = {
    fontFamily: fontFamily || FONTS.SHARP_SANS_DISPLAY_NO1,
    fallBackFontFamily: FONTS.SHARP_SANS_DISPLAY_NO1,
    isRTL,
  };

  return (
    <>
      <Cookies />
      <Userway />
      <ThemeProvider theme={theme}>
        {helmet}
        {displayHeader && <Header backgroundColor={headerBackgroundColor} />}
        <Main>
          {children}
          {displayMailing && <MailingList />}
        </Main>
        {displayFooter && <Footer/>}
        <SignUpModal />
        <LanguagePickerModal />
      </ThemeProvider>
    </>
  );
};

const Main = styled.main`
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};

  .grecaptcha-badge {
    z-index: 10;
  }
`;

export default Page;
