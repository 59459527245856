
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
query {
  directus{  
    shoppable_product {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          id
          product_number_id
          variant_sku
        }
    }
  }
}
`;

  export const useShoppableProductsStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  