import { useMemo } from "react";
import { VariantCard } from "../components/ShopOurPacks/ShopCard";
import { getAssetURL } from "../helpers/assets";
import { ROUTES } from "../helpers/routes";
import { getTranslations } from "../helpers/translations";
import { isPublished } from "../helpers/directus";
import useIntl from "./useIntl";
import { usePacksStaticQuery } from "../hooks/staticQueries/usePacksStaticQuery";

export function usePacks(): Record<string, any>[] {
  const cmsPacksQuery = usePacksStaticQuery();

  const intl = useIntl();
  const packs = useMemo(() => {
    const cmsPacksData = cmsPacksQuery?.directus?.pack_page;
    if (!cmsPacksData) return [];

    return cmsPacksData
      ?.filter((pack: any) => {
        const packTranslations =
          getTranslations(pack, "translations", intl.locale) ?? {};
        return isPublished(packTranslations?.status);
      })
      ?.map((pack) => {
        const packTranslations =
          getTranslations(pack, "translations", intl.locale) ?? {};
        const { pack_content_pack, status } = packTranslations;

        const { variant_list } = isPublished(status)
          ? getTranslations(pack_content_pack, "translations", intl.locale) ??
            {}
          : [];

        return {
          variants: variant_list
            ?.map((_variant: any) => {
              const translatedVariant =
                getTranslations(
                  _variant,
                  "pack_variant_id.translations",
                  intl.locale
                ) ?? {};

              const variantSlug = _variant?.pack_variant_id?.variant_slug;

              const { shoppable_product, status } = translatedVariant;

              const shoppableProduct = getTranslations(
                shoppable_product,
                "translations",
                intl.locale
              );

              const { product_title, variant_title } = shoppableProduct;

              return {
                status,
                id: _variant?.id,
                image: {
                  src: getAssetURL(shoppableProduct?.small_image),
                  alt: shoppableProduct?.small_image_alt,
                },
                title:
                  product_title === variant_title || !variant_title
                    ? product_title
                    : `${product_title} - ${variant_title}`,
                link: `${ROUTES.PRODUCT_DETAIL}/${pack?.pack_page_slug}?variant=${variantSlug}`,
                shopifyVariant: {
                  variantSKU: shoppableProduct?.variant_sku,
                  slug: variantSlug,
                  productNumberId: shoppableProduct?.product_number_id,
                  variantTitle: shoppableProduct?.variant_title,
                  productTitle: shoppableProduct?.product_title,
                },
              } as VariantCard;
            })
            ?.filter(({ status }: { status: string }) => {
              return isPublished(status);
            }),
        };
      })
      .filter(({ variants }) => variants?.length > 0);
  }, [cmsPacksQuery]);
  return packs;
}

export default usePacks;
