import * as React from "react";
import {
  HTMLAttributes,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import { animated, useSpring } from "react-spring";

export enum ButtonType {
  PRIMARY_LIGHT = "PRIMARY_LIGHT",
  PRIMARY_DARK = "PRIMARY_DARK",
  SECONDARY_LIGHT = "SECONDARY_LIGHT",
}

export type ButtonProps = {
  label: string;
  submit?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  className?: string;
} & HTMLAttributes<HTMLButtonElement>;

export const Button = ({
  label,
  submit,
  disabled = false,
  type = ButtonType.PRIMARY_LIGHT,
  className,
  ...props
}: ButtonProps): ReactElement<HTMLButtonElement> => {
  const [active, setActive] = useState<boolean>(false);

  const hoverBackgroundAnimation = useMemo(() => {
    const transform = `scale(${active ? 0.95 : 1})`;
    let background = active ? "#cbebbd" : "#75c154";
    if (type === ButtonType.PRIMARY_DARK) {
      background = active ? "#3e3e3e" : "#000000";
    }
    if (type === ButtonType.SECONDARY_LIGHT) {
      background = active ? "#CBEBBD" : "#ffffff";
    }
    return {
      transform,
      background,
    };
  }, [active]);

  const backgroundStyles = useSpring(hoverBackgroundAnimation);

  const Component = useMemo(() => {
    if (type === ButtonType.PRIMARY_DARK) {
      return PrimaryDark;
    }
    if (type === ButtonType.SECONDARY_LIGHT) {
      return SecondaryLight;
    }
    return PrimaryLight;
  }, [type]);

  return (
    <Component
      className={className}
      disabled={disabled}
      aria-disabled={disabled}
      aria-hidden={disabled}
      aria-label={label}
      {...(submit ? { type: "submit" } : {})}
      {...props}
      onMouseEnter={() => {
        setActive(true);
        if (props.onMouseEnter && typeof props.onMouseEnter === "function") {
          props.onMouseEnter();
        }
      }}
      onMouseLeave={() => {
        setActive(false);
        if (props.onMouseLeave && typeof props.onMouseLeave === "function") {
          props.onMouseLeave();
        }
      }}
    >
      <Label aria-hidden={disabled} style={backgroundStyles}>
        {label}
      </Label>
      <Background style={backgroundStyles} />
    </Component>
  );
};

const PrimaryLight = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  color: #000000;
  background: transparent;
  cursor: pointer;
  width: 100%;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 800;
  display: block;
  letter-spacing: 0.01em;
  line-height: ${toREM(19)}rem;
  padding: ${toVWMobile(19)}vw;
  position: relative;

  &:disabled {
    color: #938d82 !important;
    cursor: not-allowed;
    > span {
      background: #4d4444 !important;
    }
    > div {
      background: #4d4444 !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(180)}vw;
    font-size: ${toREM(16)}rem;
    letter-spacing: 0.01em;
    line-height: ${toREM(19)}rem;
    padding: ${toVWDesktop(19)}vw ${toVWDesktop(27)}vw;
  }
`;

const PrimaryDark = styled(PrimaryLight)`
  color: #ffffff;
`;

const SecondaryLight = styled(PrimaryLight)`
  color: #000000;
`;

const Background = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: -1;
`;

const Label = styled(animated.span)`
  user-select: none;
  color: inherit;
  background: transparent;
  transform: none !important;
`;

export default Button;
