
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      labels {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          id
          languages_code {
            code
            name
          }
          account
          search
          variant
          minus
          plus
          open
          close
          download
          next
          previous
          play
          pause
          locator
          empty_image
          available_quantities
          ingredients_title
          nutritions_facts_title
          cooking_instructions_title
          featured_recipes
          more_recipes
          content_title
          quantity_label
          view_product_label
          collapse_product_label
          flavors_label
          product_details
          find_near_me
          this_recipe_features
          view_now
          view_recipe
          all_recipes
          order_now
          shop_now
          view_all_faqs
          you_may_also_like
          serves_label
          prep_time_label
          cook_time_label
          minutes
          hour
          hours
          back_to_all_recipes_label
          created_by_label
          add_to_cart
          first_name_placeholder
          last_name_placeholder
          business_name_placeholder
          email_placeholder
          phone_placeholder
          country_placeholder
          state_province_placeholder
          city_placeholder
          enter_zip
          back_to_contact_us
          view_sell_page
          tools_to_go_beyond
          partner_marketing_guide
          beyond_meat_brand_assets
          pop
          search_results
          all_press_releases_title
          back_to_newsroom_label
          back_to_all_press_releases_label
          back_to_all_blogs_label
          back_to_food_service
          all_press_search_placeholder
          read_more_label
          blog_all_tag
          all_blogs_title
          all_blogs_search_placeholder
          all_blogs_back_to_blog
          newsroom_tab_title
          blog_tab_title
          newsroom_title
          newsroom_all_press_releases
          blog_all_articles
          assets_title
          assets_images
          assets_videos
          assets_fact_sheets
          shipping
          go
          edit
          delete
          primary_address
          edit_addresses
          shipping_address
          manage_addresses
          billing_address
          default_address
          add_a_new_address
          address
          address_1_placeholder
          address_2_placeholder
          save_changes
          order
          date
          payment_status
          fullfilment_status
          tax
          product
          subtotal
          total
          learn_more
          password_placeholder
          shop_online
          enter_zip
          view
          more_tasty_items
          download_sell_sheet
          out_of_stock
          sign_up
          language_selector_title
          language_selector_dropdown
          buy_online
          back
        }
      }
    }
  }
`;

  export const useLabelsStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  