import * as React from "react";
import styled from "styled-components";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import { useShopifyProduct } from "../../../hooks/useShopifyProduct";
import { Product, Variant } from "../../../services/shopify/types";
import { VariantCard } from "../../ShopOurPacks/ShopCard";
import currencyformatter from "currency-formatter";

type ResultItemProps = {
  item: VariantCard;
};

export default function ResultItem({
  item: variant,
}: ResultItemProps): JSX.Element {
  const shopifyProduct: Product | null = useShopifyProduct(
    variant?.shopifyVariant?.productNumberId
  );

  const shopifyVariant: Variant | null | undefined = React.useMemo(() => {
    try {
      return shopifyProduct?.variants?.find(
        (v) => v.sku === variant?.shopifyVariant?.variantSKU
      );
    } catch (e) {
      return null;
    }
  }, [shopifyProduct, variant]);

  return (
    <Container href={variant.link} target="_blank">
      <Image src={variant.image.src} alt={variant.image.alt} />
      <Heading>{variant.title}</Heading>
      <Price>{`${currencyformatter.format(shopifyVariant?.priceV2?.amount, {
        code: shopifyVariant?.priceV2.currencyCode,
      })}`}</Price>
    </Container>
  );
}

const Container = styled.a`
  display: inline-block;
  width: ${toVWMobile(330)}vw;
  height: ${toVWMobile(324)}vw;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  font-weight: 600;
  line-height: ${toREM(22)}rem;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(330)}vw;
    height: ${toVWDesktop(324)}vw;
  }

  &:hover {
    cursor: pointer;
    background-color: #033305;
    color: #ffffff;

    [class*="Price"] {
      color: #75c154;
    }
  }
`;

const Image = styled.img`
  display: inline-block;
  width: ${toVWMobile(213)}vw;
  height: ${toVWMobile(189)}vw;
  margin-bottom: ${toVWMobile(30)}vw;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(213)}vw;
    height: ${toVWDesktop(189)}vw;
    margin-bottom: ${toVWDesktop(30)}vw;
  }
`;

const Heading = styled.h5`
  font-size: ${toREM(18)}rem;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${toVWMobile(9)}vw;
  letter-spacing: 0.01em;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(9)}vw;
  }
`;

const Description = styled.p`
  margin: 0;
  width: 100%;
  padding: 0 ${toVWMobile(16)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(16)}vw;
  }

  // TODO: remove next rules
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Price = styled.span`
  color: #2b6447;
`;
