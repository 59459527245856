export const getAssetURL = (
  asset: string | { id: string; filename_disk: string }
): string | undefined => {
  if (!asset) {
    return undefined;
  }
  if (typeof asset === "object" && asset?.filename_disk) {
    return `${process.env.GATSBY_CMS_DIRECTUS_S3_URL}/${asset?.filename_disk}`;
  }
  if (typeof asset === "object" && asset?.id) {
    return `${process.env.GATSBY_CMS_API_URL}/assets/${asset?.id}?access_token=${process.env.GATSBY_CMS_DIRECTUS_TOKEN}`;
  }
  return `${process.env.GATSBY_CMS_API_URL}/assets/${asset}?access_token=${process.env.GATSBY_CMS_DIRECTUS_TOKEN}`;
};
