import * as React from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { Link } from "../../shared/Link";
import BackIcon from "../../../assets/images/back_icon.svg";
import { toREM, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import * as CommonHeaderTypes from "../types";

export type DrawerMenuItemViewProps = {
  onBackClick: () => void;
  subMenuItem?: CommonHeaderTypes.SubMenuItemWithOptions;
  open: boolean;
};

const clientSideWindow =
  typeof window !== "undefined"
    ? window
    : {
        innerWidth: 0,
      };

const DrawerMenuItemView = ({
  onBackClick,
  subMenuItem,
  open,
}: DrawerMenuItemViewProps) => {
  const styles = useSpring({
    right: open ? 0 : -clientSideWindow.innerWidth,
  });
  if (!subMenuItem) {
    return null;
  }
  return (
    <Container style={styles}>
      <BackButtonContainer onClick={onBackClick}>
        <BackImage src={BackIcon} alt={"Back"} />
        <BackText>Back</BackText>
      </BackButtonContainer>
      <Title>{subMenuItem?.label}</Title>
      <Content>
        {subMenuItem?.options?.map((option) => (
          <>
            <Subtitle>{option.name}</Subtitle>
            {option?.items?.map((item) => (
              <>
                <Item>
                  <ImageContainer>
                    <ItemImage src={item?.image} alt={item?.imageAlt} />
                  </ImageContainer>
                  <ItemName to={item?.menuItemLink}>{item?.name}</ItemName>
                </Item>
              </>
            ))}
          </>
        ))}
      </Content>
    </Container>
  );
};

const Container = styled(animated.div)`
  width: 100vw;
  position: fixed;
  top: ${toVWMobile(56)}vw;
  right: -${clientSideWindow.innerWidth}px;
  bottom: 0;
  background-color: #ffffff;
  z-index: 101;
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const BackButtonContainer = styled.button`
  border: none;
  padding: ${toVWMobile(20)}vw;
  display: flex;
  flex-direction: ${(props) => (props.theme.isRTL ? "row-reverse" : "row")};
  justify-content: ${(props) =>
    props.theme.isRTL ? "flex-end" : "flex-start"};
  align-items: center;
  background-color: transparent;
`;

const BackImage = styled.img`
  object-fit: contain;
  width: ${toVWMobile(20)}vw;
  height: auto;
`;

const BackText = styled.span`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  margin-left: ${toVWMobile(8)}vw;
  font-weight: 700;
  line-height: ${toREM(16)}rem;
  letter-spacing: 0.01em;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  margin: ${(props) =>
    props.theme.isRTL
      ? `0 ${toVWMobile(20)}vw  ${toVWMobile(24)}vw 0`
      : `0 0  ${toVWMobile(24)}vw ${toVWMobile(20)}vw`};
  font-weight: 900;
  line-height: ${toREM(31)}rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  padding: ${toVWMobile(24)}vw ${toVWMobile(20)}vw;
  font-weight: 800;
  line-height: ${toREM(31)}rem;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #ffffff;
`;

const Content = styled.div`
  background-color: #012102;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Item = styled.div`
  padding: ${toVWMobile(18)}vw;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ItemName = styled(Link)`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 800;
  line-height: ${toREM(24)}rem;
  letter-spacing: 0;
  color: #ffffff;
  text-decoration: none;
`;

const ImageContainer = styled.div`
  height: ${toVWMobile(165)}vw;
  width: ${toVWMobile(219)}vw;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default DrawerMenuItemView;
