import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { Link } from "../shared/Link";
import Collapsible from "react-collapsible";
import ArrowUp from "../../assets/images/arrow_up.svg";
import ArrowDown from "../../assets/images/arrow_down.svg";
import instagram from "../../assets/images/social_icons/instagram.svg";
import youtube from "../../assets/images/social_icons/youtube.svg";
import facebook from "../../assets/images/social_icons/facebook.svg";
import linkedin from "../../assets/images/social_icons/linkedin.svg";
import x from "../../assets/images/social_icons/x.svg";
import threads from "../../assets/images/social_icons/threads.svg";
import { useFooterData } from "../../hooks/useFooterData";
import { BREAKPOINTS } from "../../helpers/theme";
import LanguagePicker from "../LanguagePicker";

type SocialType = {
  social_title: string;
  instagram_url: string;
  youtube_url: string;
  facebook_url: string;
  threads_url: string;
  twitter_url: string;
  linkedin_url: string;
};

type SectionType = {
  section_title: string;
  options: Array<{ label: string; url: string }>;
};

type SubFooterType = {
  copyright: string;
  links: Array<{ label: string; url: string }>;
};

type FooterProps = {
  isRTL?: boolean;
};

export type FooterData = {
  isPublished?: boolean;
  social: SocialType;
  sections: Array<SectionType>;
  sub_footer: SubFooterType;
};

const renderSections = (sections: Array<SectionType>) => {
  return (
    <>
      {(sections || []).map((section) => {
        const { section_title, options } = section;
        return (
          <LinksColumn key={section_title}>
            <MobileContainer
              transitionTime={300}
              trigger={
                <LinkTitle>
                  {section_title}
                  <CollapseIcon src={ArrowDown} alt="Expand" />
                </LinkTitle>
              }
              triggerWhenOpen={
                <LinkTitle>
                  {section_title}
                  <CollapseIcon src={ArrowUp} alt="Collapse" />
                </LinkTitle>
              }
            >
              <LinkItems>
                {options.map((option) => {
                  const { label, url } = option;
                  return (
                    <LinkItem key={label} to={url || ""}>
                      {label}
                    </LinkItem>
                  );
                })}
              </LinkItems>
            </MobileContainer>
            <DesktopContainer>
              <LinkTitle>{section_title}</LinkTitle>
              <LinkItems>
                {options.map((option) => {
                  const { label, url } = option;
                  return (
                    <LinkItem key={label} to={url || ""}>
                      {label}
                    </LinkItem>
                  );
                })}
              </LinkItems>
            </DesktopContainer>
          </LinksColumn>
        );
      })}
    </>
  );
};

const renderLegals = (links: Array<{ label: string; url: string }>) => {
  return (
    <Legals>
      {(links || [])
        .filter((l) => !l.isCookie)
        .map((l) => {
          return (
            <LegalItemLink key={l.label} to={l?.url || ""}>
              {l.label}
            </LegalItemLink>
          );
        })}
      <LegalItem id="teconsent" />
    </Legals>
  );
};

const Footer = ({ isRTL = false }: FooterProps): JSX.Element => {
  const data: FooterData = useFooterData();
  const { social, sections, sub_footer, isPublished } = data;
  const {
    social_title,
    instagram_url,
    youtube_url,
    facebook_url,
    threads_url,
    twitter_url,
    linkedin_url,
  } = social;

  if (!isPublished) return null;

  const getCookie = useCallback(() => {
    try {
      return sub_footer?.links?.find((l) => l.isCookie);
    } catch (e) {
      console.log(e);
      return null;
    }
  }, [sub_footer?.links]);

  useEffect(() => {
    const tId = setInterval(() => {
      const cookie = getCookie();
      const element = document?.querySelector("#teconsent>a");
      if (element) {
        element.innerHTML = cookie?.label || "";
        clearInterval(tId);
      }
    }, 500);
    return () => {
      try {
        clearInterval(tId);
      } catch (e) {
        console.log(e);
      }
    };
  }, [getCookie]);

  return (
    <FooterContainer>
      <MainContainer>
        <SocialColumn>
          <SocialTitle>{social_title}</SocialTitle>
          <SocialIcons>
            <a href={instagram_url} target="_blank" rel="noreferrer">
              <Icon src={instagram} alt="Instagram" />
            </a>
            <a href={youtube_url} target="_blank" rel="noreferrer">
              <Icon src={youtube} alt="Youtube" />
            </a>
            <a href={facebook_url} target="_blank" rel="noreferrer">
              <Icon src={facebook} alt="Facebook" />
            </a>
            <a href={threads_url} target="_blank" rel="noreferrer">
              <Icon src={threads} alt="Threads" />
            </a>
            <a href={twitter_url} target="_blank" rel="noreferrer">
              <Icon src={x} alt="X" />
            </a>
            <a href={linkedin_url} target="_blank" rel="noreferrer">
              <Icon src={linkedin} alt="Linkedin" />
            </a>
          </SocialIcons>
          <DesktopOnly>
            <LanguagePickerContainer>
              <LanguagePicker />
            </LanguagePickerContainer>
          </DesktopOnly>
        </SocialColumn>
        {renderSections(sections)}

        <MobileOnly>
          <LanguagePickerContainer>
            <LanguagePicker />
          </LanguagePickerContainer>
        </MobileOnly>
      </MainContainer>
      <LegalContainer>
        {sub_footer.copyright}
        {renderLegals(sub_footer.links)}
      </LegalContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  background-color: #ffffff;
  padding: ${toVWMobile(40)}vw 0;

  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(65)}vw 0 0 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${toVWMobile(24)}vw ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${toVWDesktop(100)}vw ${toVWDesktop(65)}vw;
  }
`;

const SocialColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(318)}vw;
    margin-right: ${(props) =>
      props.theme.isRTL ? 0 : `${toVWDesktop(104)}vw`};
    margin-left: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(104)}vw` : 0};
  }
`;

const SocialTitle = styled.div`
  font-size: ${toREM(24)}rem;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  letter-spacing: 0.06em;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(22)}rem;
    letter-spacing: normal;
    font-weight: 700;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${toVWMobile(22)}vw 0 ${toVWMobile(22)}vw;
  justify-content: space-between;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${toVWDesktop(20)}vw 0;
  }
`;

const Icon = styled.img`
  width: ${toVWMobile(25)}vw;
  height: ${toVWMobile(25)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(25)}vw;
    height: ${toVWDesktop(25)}vw;
  }
`;

const CollapseIcon = styled(Icon)`
  width: ${toVWMobile(16)}vw;
  height: ${toVWMobile(8)}vw;
`;

const LinksColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(200)}vw;
  }
`;

const LinkTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  margin-top: ${toVWMobile(28)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(16)}rem;
    letter-spacing: 0.01em;
    margin-top: 0;
    img {
      display: none;
    }
  }
`;

const LinkItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(17)}vw 0 ${toVWMobile(26)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(17)}vw 0 ${toVWDesktop(13)}vw;
  }
`;

const LinkItem = styled(Link)`
  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  font-weight: 600;
  text-align: start;
  text-transform: none;
  text-decoration: none;
  margin: ${toVWMobile(15)}vw 0 ${toVWMobile(13)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${toVWDesktop(15)}vw 0 ${toVWDesktop(12)}vw;
    font-size: ${toREM(15)}rem;
  }
`;

const LegalContainer = styled.div`
  display: flex;
  direction: ltr;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  border-top: ${toVWMobile(1)}vw solid #000000;
  padding: ${toVWMobile(41)}vw ${toVWMobile(24)}vw 0;
  color: #2e282a;
  font-size: ${toREM(12)}rem;
  font-weight: 500;
  text-transform: capitalize;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    border-top: ${toVWDesktop(1)}vw solid #000000;
    border-bottom: none;
    padding: ${toVWDesktop(42)}vw ${toVWDesktop(100)}vw ${toVWDesktop(40)}vw;
  }
`;

const Legals = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: ${(props) => (props.theme.isRTL ? "row-reverse" : "row")};
  }
`;

const LegalItemLink = styled(Link)`
  color: #2e282a;
  margin-top: ${toVWMobile(28)}vw;
  font-size: ${toREM(12)}rem;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 500;
  text-decoration: underline;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${(props) =>
      props.theme.isRTL
        ? `${toVWDesktop(24)}vw 0 0 ${toVWDesktop(70)}vw`
        : `${toVWDesktop(24)}vw ${toVWDesktop(70)}vw 0 0`};
  }
`;

const LegalItem = styled.div`
  color: #2e282a;
  margin-top: ${toVWMobile(28)}vw;
  font-size: ${toREM(12)}rem;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 500;
  text-decoration: underline;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${toVWDesktop(24)}vw ${toVWDesktop(70)}vw 0 0;
  }
`;

const MobileContainer = styled(Collapsible)`
  span,
  .Collapsible__trigger {
    width: 100%;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
  }
`;

const LanguagePickerContainer = styled.div`
  margin-top: ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(60)}vw;
  }
`;

const DesktopOnly = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
  }
`;
const MobileOnly = styled.div`
  display: block;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

export default Footer;
