import { uniqueId } from "lodash";
import React, { ReactElement, useMemo } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import check from "../../../assets/images/checkmark.svg";
import { rem, vwDesktop, vwMobile } from "../../../helpers/styles";
import { MEDIA_DESKTOP } from "../../../helpers/theme";

export type CheckboxProps = {
  label: string;
  checked: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export default function Checkbox({
  label,
  checked,
  ...props
}: CheckboxProps): ReactElement {
  const id = useMemo(() => uniqueId("checkbox-"), []);
  return (
    <Container>
      <Box>
        <input
          id={id}
          type="checkbox"
          role="checkbox"
          aria-checked={checked}
          checked={checked}
          {...props}
        />
        {checked && <InlineSVG src={check} />}
      </Box>
      {label && (
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${vwMobile(16)};

  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(14)};
  font-weight: 600;

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(16)};
  }

  label a {
    color: #75c154;
  }
`;

const Box = styled.div`
  position: relative;
  flex-shrink: 0;

  width: ${vwMobile(20)};
  height: ${vwMobile(20)};

  cursor: pointer;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(20)};
    height: ${vwDesktop(20)};
  }

  input {
    color: #75c154;
    cursor: pointer;

    &:checked {
      color: white;
      background-color: white;
    }

    appearance: none;
    width: ${vwMobile(20)};
    height: ${vwMobile(20)};
    border: ${vwMobile(2)} solid;

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(20)};
      height: ${vwDesktop(20)};
      border-width: ${vwDesktop(2)};
    }
  }

  svg {
    pointer-events: none;
    top: ${vwMobile(4)};
    left: ${vwMobile(4)};
    width: ${vwMobile(13)};
    height: ${vwMobile(11)};
    pointer-events: none;

    ${MEDIA_DESKTOP} {
      top: ${vwDesktop(4)};
      left: ${vwDesktop(4)};
      width: ${vwDesktop(13)};
      height: ${vwDesktop(11)};
    }

    fill: black;
    position: absolute;
  }
`;
