import * as React from "react";
import styled from "styled-components";
import { useCallback, useMemo, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link, LinkProps } from "../Link";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";

export enum ButtonType {
  PRIMARY_LIGHT = "PRIMARY_LIGHT",
  PRIMARY_DARK = "PRIMARY_DARK",
  SECONDARY_LIGHT = "SECONDARY_LIGHT",
}

export type ButtonLinkProps = {
  label: string;
  link: string;
  type?: ButtonType;
  className?: string;
  iconComponent?: React.ReactElement;
  anchor?: boolean;
  blank?: boolean;
  disabled?: boolean;
};

export const ButtonLink = ({
  label,
  link,
  type = ButtonType.PRIMARY_LIGHT,
  className,
  iconComponent,
  ...props
}: ButtonLinkProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false);

  const hoverBackgroundAnimation = useMemo(() => {
    const transform = `scale(${active ? 0.95 : 1})`;
    let background = active ? "#cbebbd" : "#75c154";
    if (type === ButtonType.PRIMARY_DARK) {
      background = active ? "#3e3e3e" : "#000000";
    }
    if (type === ButtonType.SECONDARY_LIGHT) {
      background = active ? "#CBEBBD" : "#ffffff";
    }
    return {
      transform,
      background,
    };
  }, [active]);
  const backgroundStyles = useSpring(hoverBackgroundAnimation);

  const Component = useMemo(() => {
    if (type === ButtonType.PRIMARY_DARK) {
      return PrimaryDark;
    }
    if (type === ButtonType.SECONDARY_LIGHT) {
      return SecondaryLight;
    }
    return PrimaryLight;
  }, [type]);

  return (
    <Component
      className={className}
      to={link}
      {...props}
      onMouseEnter={
        !props.disabled
          ? () => {
              setActive(true);
              if (
                props.onMouseEnter &&
                typeof props.onMouseEnter === "function"
              ) {
                props.onMouseEnter();
              }
            }
          : () => null
      }
      onMouseLeave={
        !props.disabled
          ? () => {
              setActive(false);
              if (
                props.onMouseLeave &&
                typeof props.onMouseLeave === "function"
              ) {
                props.onMouseLeave();
              }
            }
          : () => null
      }
    >
      {!!iconComponent && iconComponent}
      <Label>{label}</Label>
      <Background style={backgroundStyles} />
    </Component>
  );
};

const PrimaryLight = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  color: #000000;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  display: block;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 800;
  letter-spacing: 0.01em;
  line-height: ${toREM(19)}rem;
  padding: ${toVWMobile(19)}vw;
  align-self: center;
  position: relative;

  &[disabled] {
    color: black !important;
    cursor: default;
    > div {
      background: #a55121 !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: fit-content;
    min-width: ${toVWDesktop(180)}vw;
    font-size: ${toREM(16)}rem;
    letter-spacing: 0.01em;
    line-height: ${toREM(19)}rem;
    padding: ${toVWDesktop(19)}vw ${toVWDesktop(27)}vw;
    transition: transform 0.5s;
  }
`;

const Background = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: -1;
`;

const PrimaryDark = styled(PrimaryLight)`
  color: #ffffff;
`;

const SecondaryLight = styled(PrimaryLight)`
  color: #000000;
`;

const Label = styled(animated.span)``;

export default ButtonLink;
