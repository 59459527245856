import { useCallback, useEffect, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useAppSelector } from "./useAppSelector";
import { ShopifyService } from "../services/shopify/shopify";
import { Checkout, LineItem } from "../services/shopify/types";
import { useAppDispatch } from "./useAppDispatch";
import { setCheckout } from "../store/shopify";
import { removeQueryParamsFromUrl } from "../helpers/location";

export const useShopifyCheckout = (shouldValidate?: boolean) => {
  const dispatch = useAppDispatch();
  const [syncCart] = useQueryParam("cart", StringParam);
  const shopifyCheckout: Checkout | null = useAppSelector(
    (state) => state.shopify.checkout,
    (a, b) =>
      a?.id === b?.id && a?.subtotalPrice?.amount === b?.subtotalPrice?.amount
  );

  const syncCartId: string | null = useMemo(() => {
    if (!syncCart) {
      return null;
    }
    const regex = /checkouts\/(\w+)\/recover\?key=(\w+)/;
    const matches = syncCart.match(regex);
    if (!matches) {
      return null;
    }
    const [_, checkoutId, recoveryKey] = matches;
    if (!checkoutId || !recoveryKey) {
      return null;
    }
    return window.btoa(
      unescape(
        encodeURIComponent(
          `gid://shopify/Checkout/${checkoutId}?key=${recoveryKey}`
        )
      )
    );
  }, [syncCart]);

  const addLineItems = useCallback(
    async (lineItems: LineItem[]) => {
      if (!shopifyCheckout?.id) {
        createCheckout();
        return;
      }
      const checkout = await ShopifyService.addLineItems(
        shopifyCheckout?.id,
        lineItems
      );
      dispatch(setCheckout(checkout));
    },
    [shopifyCheckout?.id]
  );

  const removeLineItems = useCallback(
    async (lineItems: LineItem[]) => {
      if (!shopifyCheckout?.id) {
        createCheckout();
        return;
      }
      const checkout = await ShopifyService.removeLineItems(
        shopifyCheckout?.id,
        lineItems.map((lineItem) => lineItem.id)
      );
      dispatch(setCheckout(checkout));
    },
    [shopifyCheckout?.id]
  );

  const updateLineItems = useCallback(
    async (lineItems: LineItem[]) => {
      if (!shopifyCheckout?.id) {
        createCheckout();
        return;
      }
      const checkout = await ShopifyService.updateLineItems(
        shopifyCheckout?.id,
        lineItems
      );
      dispatch(setCheckout(checkout));
    },
    [shopifyCheckout?.id]
  );

  const updateNotes = useCallback(
    async (note) => {
      try {
        if (shopifyCheckout?.id) {
          const checkout = await ShopifyService.updateNotes(
            shopifyCheckout?.id,
            note
          );
          dispatch(setCheckout(checkout));
        }
      } catch (e) {
        console.log(e);
      }
    },
    [shopifyCheckout?.id]
  );

  const createCheckout = useCallback(
    async (reset?: boolean) => {
      try {
        const lineItems: LineItem[] =
          shopifyCheckout?.lineItems?.map((lItem) => ({
            variantId: lItem?.variant?.id,
            quantity: lItem?.quantity,
          })) || [];
        const checkout = await ShopifyService.createCheckout(
          reset ? [] : lineItems
        );

        dispatch(setCheckout(checkout));
      } catch (e) {
        console.log(e);
      }
    },
    [shopifyCheckout?.id]
  );

  const validateCheckout = useCallback(async () => {
    try {
      if (!shopifyCheckout?.id && !syncCartId) {
        await createCheckout();
        return;
      }
      const checkout = await ShopifyService.getCheckout(
        syncCartId || shopifyCheckout?.id
      );
      if (checkout?.orderStatusUrl) {
        await createCheckout(true);
        return;
      }
      dispatch(setCheckout(checkout));
      if (syncCartId) {
        removeQueryParamsFromUrl();
      }
    } catch (e) {
      createCheckout();
      console.log(e);
    }
  }, [shopifyCheckout?.id, createCheckout, syncCartId]);

  useEffect(() => {
    if (shouldValidate) {
      validateCheckout();
    }
  }, [shouldValidate]);

  const obj = useMemo(() => {
    return {
      0: shopifyCheckout,
      1: {
        addLineItems,
        updateLineItems,
        removeLineItems,
      },
      shopifyCheckout,
      addLineItems,
      updateLineItems,
      updateNotes,
      removeLineItems,
    };
  }, [shopifyCheckout]);

  return obj;
};
