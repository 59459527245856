import * as React from "react";
import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import SVG from "react-inlinesvg";
import currencyFormatter from "currency-formatter";
import CartItem from "./CartItem";

import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import {
  rem,
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import CloseIcon from "../../../assets/images/close_icon.svg";
import { useShopifyCheckout } from "../../../hooks/useShopifyCheckout";
import { useCartLabels } from "../../../hooks/useCartLabels";
import { LineItem } from "../../../services/shopify/types";
import { Button } from "../../shared/Button";
import { useStaticLabels } from "../../../hooks/useStaticLabels";
import ButtonLink, { ButtonType } from "../../shared/ButtonLink";
import { ROUTES } from "../../../helpers/routes";
import { ShopifyService } from "../../../services/shopify/shopify";
import { useShopifyCustomer } from "../../../hooks/useShopifyCustomer";
import RecommendedProducts from "../../RecommendedProducts";

const clientSideWindow =
  typeof window !== "undefined"
    ? window
    : {
        innerWidth: 0,
      };

type CartDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CartDrawer: FC<CartDrawerProps> = ({
  isOpen,
  onClose,
}: CartDrawerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const labels = useCartLabels();
  const staticLabels = useStaticLabels();

  const { shopifyCustomer } = useShopifyCustomer();
  const { removeLineItems, updateLineItems, shopifyCheckout } =
    useShopifyCheckout(true);
  const styles = useSpring({
    right: isOpen ? 0 : -clientSideWindow.innerWidth,
  });

  const handleRemoveClick = useCallback(
    (lineItem: LineItem) => {
      removeLineItems([lineItem]);
    },
    [removeLineItems]
  );

  const handleIncrementClick = useCallback(
    (lineItem: LineItem) => {
      updateLineItems([lineItem]);
    },
    [updateLineItems]
  );

  const handleDecrementClick = useCallback(
    (lineItem: LineItem) => {
      updateLineItems([lineItem]);
    },
    [updateLineItems]
  );

  const handleCheckout = async () => {
    try {
      setLoading(true);
      if (shopifyCustomer?.accessToken?.accessToken) {
        const url = await ShopifyService.getMultipassLoginUrl(
          shopifyCustomer?.accessToken?.accessToken,
          shopifyCheckout?.webUrl
        );
        setLoading(false);
        window.location.href = url;
        return;
      }
      setLoading(false);
      window.location.href = shopifyCheckout?.webUrl;
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Container style={styles}>
      <ItemsContainer>
        <Header>
          <Title>{labels?.cartTitle}</Title>
          <IconButton aria-label={staticLabels?.close} onClick={onClose}>
            <Icon src={CloseIcon} width={18} />
          </IconButton>
        </Header>
        {!shopifyCheckout?.lineItems?.length && (
          <EmptyCartMessage>
            {labels.cartEmpty && (
              <EmptyCartTitle>{labels.cartEmpty}</EmptyCartTitle>
            )}
            {labels.shopProducts && (
              <ButtonLink
                type={ButtonType.PRIMARY_LIGHT}
                label={labels.shopProducts}
                link={ROUTES.SHOP}
              />
            )}
          </EmptyCartMessage>
        )}
        {shopifyCheckout?.lineItems?.map((lineItem) => (
          <CartItem
            key={lineItem?.id}
            lineItem={lineItem}
            onDecrement={handleDecrementClick}
            onIncrement={handleIncrementClick}
            onRemove={handleRemoveClick}
            labels={{
              remove: labels?.remove,
            }}
          />
        ))}
        <RecommendedProducts />
      </ItemsContainer>
      <Footer>
        <CheckoutBtn
          onClick={handleCheckout}
          disabled={!(shopifyCheckout?.lineItems?.length > 0) || loading}
          label={
            shopifyCheckout?.lineItems?.length > 0
              ? `${labels?.checkout} ${currencyFormatter.format(
                  shopifyCheckout?.subtotalPrice?.amount,
                  { code: shopifyCheckout?.subtotalPrice.currencyCode }
                )}`
              : `${labels?.checkout}`
          }
        />
      </Footer>
    </Container>
  );
};

const EmptyCartMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  row-gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    row-gap: ${vwDesktop(20)};
  }
`;
const EmptyCartTitle = styled.span`
  text-transform: uppercase;
  font-size: ${rem(16)};
  font-weight: 700;
  color: #000000;
  background-color: #ffffff;
`;

const Container = styled(animated.div)`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: initial;
    width: 35%;
  }
`;

const Icon = styled(SVG)<{ width: number }>`
  width: ${(props) => toVWMobile(props.width)}vw;
  height: auto;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${(props) => toVWDesktop(props.width)}vw;
  }
`;

const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${toVWMobile(20)}vw;
  overflow-y: auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(20)}vw;
  }
`;

const Title = styled.h2`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.00144em;
  text-transform: uppercase;
`;

const Footer = styled.div`
  border-top: 2px solid #2b6447;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-height: ${toVWDesktop(240)}vw;
    padding: ${toVWDesktop(20)}vw;
  }
`;

const CheckoutBtn = styled(Button)`
  margin-bottom: ${toVWMobile(11)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(11)}vw;
  }
`;

export default CartDrawer;
