import { useEffect, useMemo, useState } from "react";
import { shuffle } from "lodash";
import { useShopifyCheckout } from "./useShopifyCheckout";
import { useShoppableProductsStaticQuery } from "./staticQueries/useShoppableProductsStaticQuery";
import { usePacks } from "./usePacks";
import { useIntl } from "./useIntl";
import { getTranslations } from "../helpers/translations";

export const useRecommendedProducts = () => {
  const intl = useIntl();
  const { shopifyCheckout } = useShopifyCheckout();
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const shoppableProductsQueryData = useShoppableProductsStaticQuery();
  const shoppableProducts = useMemo(() => {
    return (
      shoppableProductsQueryData?.directus?.shoppable_product?.map(
        (sProduct) => {
          return sProduct?.translations[0];
        }
      ) || []
    );
  }, [shoppableProductsQueryData?.directus?.shoppable_product, intl?.locale]);
  const packs = usePacks();

  useEffect(() => {
    try {
      const normalizedCartProducts = {};

      shopifyCheckout?.lineItems?.forEach((p) => {
        normalizedCartProducts[p?.variant?.sku] = p;
      });

      const rProducts = shoppableProducts
        .filter((product) => !normalizedCartProducts[product.variant_sku])
        .map((product) => {
          let variant = undefined;
          const pack = packs.find((p) => {
            variant = p?.variants?.find(
              (variant) =>
                variant?.shopifyVariant?.variantSKU === product?.variant_sku &&
                variant?.shopifyVariant?.productNumberId ===
                  product?.product_number_id
            );
            return !!variant;
          });
          return {
            ...product,
            pack,
            variant,
          };
        });
      setRecommendedProducts(shuffle(rProducts));
    } catch (e) {
      setRecommendedProducts([]);
    }
  }, [shoppableProducts, shopifyCheckout, packs]);

  return recommendedProducts;
};
