import { useIntl } from "./useIntl";
import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { Labels } from "../components/Header/CartDrawer/types";
import { useCartStaticQuery } from "../hooks/staticQueries/useCartStaticQuery";

export const useCartLabels = (): Labels => {
  const intl = useIntl();
  const data = useCartStaticQuery();

  const labels = useMemo<Labels>((): Labels => {
    const translatedData = getTranslations(
      data,
      "directus.cart.translations",
      intl.locale
    );

    return {
      cartTitle: translatedData?.title,
      checkout: translatedData?.checkout_label,
      remove: translatedData?.remove_button_label,
      cartEmpty: translatedData?.empty_cart_label,
      shopProducts: translatedData?.shop_our_products_label,
    };
  }, [data, intl]);

  return labels;
};
