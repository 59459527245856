import { isEqual } from "lodash";
import * as React from "react";
import { useMemo, useRef } from "react";
import SVG from "react-inlinesvg";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import AvatarIcon from "../../../assets/images/avatar_icon.svg";
import BeyondMeatLogo from "../../../assets/images/beyond_meat_logo.svg";
import BeyondMeatTextImage from "../../../assets/images/beyond_meat_text.svg";
import HamburgerIcon from "../../../assets/images/hamburger.svg";
import SearchIcon from "../../../assets/images/search_icon.svg";
import ShoppingCartIcon from "../../../assets/images/shopping_cart_icon.svg";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useStaticLabels } from "../../../hooks/useStaticLabels";
import { Link } from "../../shared/Link";
import { NavbarState } from "../Header";
import * as CommonHeaderTypes from "../types";
import { NavbarOptions } from "../types";
import MenuItem from "./MenuItem";
import shopify from "../../../config/shopify";
import { useSettings } from "../../../hooks/useSettings";

export enum NavBarAnimations {
  IN = "IN",
  OUT = "OUT",
}

export enum NavBarType {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

export type NavBarProps = {
  type: NavBarType;
  backgroundColor?: string;
  transparent?: boolean;
  onMenuClick: () => void;
  onMenuItemClick: (mItem: CommonHeaderTypes.MenuItem) => void;
  menuItems: Array<CommonHeaderTypes.MenuItem>;
  selectedMenuItem: CommonHeaderTypes.MenuItem | undefined;
  animation?: NavBarAnimations;
  className?: string;
  onSearchClick: () => void;
  onAvatarClick: () => void;
  onCartClick: () => void;
  onMouseEnterSearch?: () => void;
  onMouseEnterAvatar?: () => void;
  onMouseEnterCart?: () => void;
  navState: NavbarState;
  immediate?: boolean;
  id?: string;
};

const NavBar = ({
  type = NavBarType.DARK,
  backgroundColor: defaultBackgroundColor,
  transparent,
  onMenuClick,
  onMenuItemClick,
  onSearchClick,
  onAvatarClick,
  onCartClick,
  onMouseEnterSearch,
  onMouseEnterAvatar,
  onMouseEnterCart,
  navState,
  menuItems,
  selectedMenuItem,
  animation,
  className,
  immediate = false,
  id,
}: NavBarProps): JSX.Element => {
  const staticLabels = useStaticLabels();
  const rootElement = useRef<HTMLElement | null>(null);
  const shopifyCheckout = useAppSelector(
    (state) => state.shopify.checkout,
    (a, b) =>
      a?.id === b?.id && a?.subtotalPrice?.amount === b?.subtotalPrice?.amount
  );
  const isShoppifyEnabled = useSettings();

  const { backgroundColor, fontColor } = useMemo(() => {
    if (animation === NavBarAnimations.OUT) {
      return {
        backgroundColor: transparent
          ? "transparent"
          : defaultBackgroundColor
          ? defaultBackgroundColor
          : "#3F8644",
        fontColor: "#FFFFFF",
      };
    }
    return {
      backgroundColor: "#FFFFFF",
      fontColor: "#000000",
    };
  }, [type, transparent, defaultBackgroundColor, animation]);

  const props = useSpring({
    backgroundColor,
    fontColor,
    immediate,
  });

  return (
    <Content
      className={className}
      ref={(r) => (rootElement.current = r)}
      style={props}
      backgroundColor={backgroundColor}
      id={id}
    >
      <ActionsContainer>
        <MobileOnlyTransparentButton
          aria-label={"Open Menu"}
          onClick={onMenuClick}
        >
          <Hamburger
            src={HamburgerIcon}
            mobile={20}
            desktop={0}
            color={fontColor}
          />
        </MobileOnlyTransparentButton>
        <BeyondMeat theme={type}>
          <Link to={"/"}>
            <BeyondMeatIcon
              theme={type}
              src={BeyondMeatLogo}
              mobile={26.16}
              desktop={32.03}
            />
          </Link>
          <Link to={"/"}>
            <BeyondMeatText
              src={BeyondMeatTextImage}
              color={fontColor}
              mobile={103}
              desktop={127}
            />
          </Link>
        </BeyondMeat>
        <MenuItemsContainer>
          {menuItems?.map((mItem) => (
            <>
              <MenuItem
                fontColor={fontColor}
                active={selectedMenuItem?.label === mItem?.label}
                onClick={(menuItem) => onMenuItemClick(menuItem)}
                onMouseEnter={(menuItem) => onMenuItemClick(menuItem)}
                menuItem={mItem}
              />
            </>
          ))}
        </MenuItemsContainer>
      </ActionsContainer>
      {isShoppifyEnabled && (
        <ActionsContainer>
          <NavButton
            className={navState.show === NavbarOptions.search ? "active" : ""}
            theme={type}
            onClick={onSearchClick}
            onMouseEnter={onMouseEnterSearch}
            aria-label={staticLabels?.search}
          >
            <Search
              src={SearchIcon}
              mobile={20}
              desktop={20}
              color={fontColor}
            />
          </NavButton>
          {shopify.enableAccount && (
            <NavButton
              className={navState.show === NavbarOptions.login ? "active" : ""}
              theme={type}
              onClick={onAvatarClick}
              onMouseEnter={onMouseEnterAvatar}
              desktopOnly
              aria-label={staticLabels?.account}
            >
              <Avatar
                src={AvatarIcon}
                mobile={20}
                desktop={20}
                color={fontColor}
              />
            </NavButton>
          )}
          {shopify.enableCart && (
            <CartButtonBg
              displayBadge={(shopifyCheckout?.lineItems?.length || 0) > 0}
              data-items={shopifyCheckout?.lineItems?.length || 0}
              className={navState.show === NavbarOptions.cart ? "active" : ""}
              theme={type}
              onClick={onCartClick}
              onMouseEnter={onMouseEnterCart}
              aria-label={staticLabels?.cart}
            >
              <Cart
                src={ShoppingCartIcon}
                mobile={20}
                desktop={22.5}
                color={fontColor}
              />
            </CartButtonBg>
          )}
        </ActionsContainer>
      )}
    </Content>
  );
};

const Content = styled(animated.nav)<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  height: ${toVWMobile(56)}vw;
  padding: ${toVWMobile(0)}vw ${toVWMobile(24)}vw ${toVWMobile(0)}vw
    ${toVWMobile(19)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(64)}vw;
    padding: ${toVWDesktop(0)}vw ${toVWDesktop(100)}vw ${toVWDesktop(0)}vw
      ${toVWDesktop(100)}vw;
  }
`;

const Icon = styled(SVG)<{ mobile: number; desktop: number; color: string }>`
  width: ${(props) => toVWMobile(props.mobile)}vw;
  height: auto;
  object-fit: contain;
  color: ${(props) => props.color};
  & path {
    fill: ${(props) => props.color};
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${(props) => toVWDesktop(props.desktop)}vw;
  }
`;

const NavButton = styled.button<{
  theme: "light" | "dark";
  desktopOnly?: boolean;
}>`
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  width: ${toVWMobile(40)}vw;
  height: ${toVWMobile(40)}vw;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${(p) =>
      p.theme === NavBarType.DARK ? "white" : "#75c154"};
  }

  display: ${(p) => (p.desktopOnly ? "none" : "flex")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    width: ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(40)}vw;
  }
`;

const TransparentButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

const MobileOnlyTransparentButton = styled(TransparentButton)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const Hamburger = styled(Icon)<{ color: string }>`
  cursor: pointer;
  & line {
    stroke: ${(props) => props.color};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const BeyondMeatIcon = styled(Icon)<{ theme: string }>`
  path {
    fill: ${(p) => (p.theme === NavBarType.LIGHT ? "#75c154" : "#75c154")};
  }
`;

const BeyondMeat = styled.div<{ theme?: string }>`
  display: flex;
  align-items: center;
  margin-left: ${toVWMobile(18)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: 0;
  }
`;

const BeyondMeatText = styled(Icon)<{ color: string }>`
  margin-left: ${toVWMobile(5.6)}vw;
  & path {
    fill: ${(props) => props.color};
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(6.8)}vw;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Search = styled(Icon)``;
const Avatar = styled(Icon)``;
const Cart = styled(Icon)``;

const CartButtonBg = styled(NavButton)<{
  displayBadge: boolean;
}>`
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: ${toVWMobile(40)}vw;
  height: ${toVWMobile(40)}vw;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    cursor: pointer;
    display: flex;
    width: ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(40)}vw;
  }

  & svg {
    margin-left: 0;
  }

  &:before {
    content: ${(props) => (props.displayBadge ? `attr(data-items)` : "none")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${toVWMobile(13)}vw;
    height: ${toVWMobile(13)}vw;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(11)}rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    position: absolute;
    top: 20%;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      width: ${toVWDesktop(13)}vw;
      height: ${toVWDesktop(13)}vw;
    }
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  margin-left: ${toVWDesktop(7)}vw;
  align-items: center;
`;

export default React.memo(NavBar, (prevProps, nextProps) => {
  return (
    prevProps.type === nextProps.type &&
    prevProps.backgroundColor === nextProps.backgroundColor &&
    prevProps.transparent === nextProps.transparent &&
    prevProps.animation === nextProps.animation &&
    prevProps.className === nextProps.className &&
    prevProps.navState === nextProps.navState &&
    prevProps.immediate === nextProps.immediate &&
    isEqual(prevProps.selectedMenuItem, nextProps.selectedMenuItem) &&
    isEqual(prevProps.menuItems, nextProps.menuItems)
  );
});
