import { get } from "lodash";

export const getTranslations = (
  data: any = {},
  path = "",
  locale = "en",
  defaultLocale = "en"
): any => {
  const translations = get(data, path) || [];
  return (
    translations.find((t) =>
      (t?.languages_code?.code || "").startsWith(locale)
    ) ||
    translations.find((t) =>
      (t?.languages_code?.code || "").startsWith(defaultLocale)
    )
  );
};
