import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { useIntl } from "./useIntl";
import { useSettingsStaticQuery } from "../hooks/staticQueries/useSettingsStaticQuery";

export const useSettings = (): boolean => {
  const intl = useIntl();
  const data = useSettingsStaticQuery();

  const isShoppifyEnabled = useMemo((): boolean => {
    const translatedData =
      getTranslations(data, "directus.settings.translations", intl.locale) ??
      {};

    return translatedData?.shopify;
  }, [data, intl]);

  return isShoppifyEnabled;
};
