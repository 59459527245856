import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { BREAKPOINTS } from "../../../helpers/theme";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import MinusIcon from "../../../assets/images/minus_icon.svg";
import PlusIcon from "../../../assets/images/plus_icon.svg";
import { LineItem, LineItemFull } from "../../../services/shopify/types";
import { Image } from "../../../types";
import { Button, ButtonType } from "../../shared/Button";
import { useStaticLabels } from "../../../hooks/useStaticLabels";
import currencyFormatter from "currency-formatter";

type CartItemProps = {
  lineItem: LineItemFull;
  onIncrement: (lineItem: LineItem) => void;
  onDecrement: (lineItem: LineItem) => void;
  onRemove: (lineItem: LineItem) => void;
  labels: {
    remove: string;
  };
};

const CartItem = ({
  lineItem,
  labels,
  onIncrement,
  onDecrement,
  onRemove,
}: CartItemProps) => {
  const staticLabels = useStaticLabels();
  const handleIncrement = useCallback(() => {
    onIncrement({
      id: lineItem.id,
      quantity: lineItem?.quantity + 1 || 1,
      variantId: lineItem.variant.id,
    });
  }, [onIncrement, lineItem]);

  const handleDecrement = useCallback(() => {
    onDecrement({
      id: lineItem.id,
      quantity: lineItem?.quantity - 1 || 1,
      variantId: lineItem.variant.id,
    });
  }, [onDecrement, lineItem]);

  const handleRemove = useCallback(() => {
    onRemove({
      id: lineItem.id,
      quantity: lineItem?.quantity,
      variantId: lineItem.variant.id,
    });
  }, [onRemove, lineItem]);

  const getTitle = useCallback(() => {
    return `${lineItem?.variant?.product?.title}`;
  }, [lineItem]);

  const getDescription = useCallback(() => {
    return lineItem?.variant?.title?.toLowerCase() ===
      "Default Title".toLowerCase()
      ? ""
      : lineItem?.variant?.title;
  }, [lineItem]);

  const getPrice = useCallback(() => {
    if (lineItem?.unitPrice?.amount) {
      return `${currencyFormatter.format(
        lineItem?.unitPrice?.amount * lineItem?.quantity,
        { code: lineItem.unitPrice.currencyCode }
      )}`;
    }
    return `${currencyFormatter.format(
      lineItem?.variant?.priceV2?.amount * lineItem?.quantity,
      { code: lineItem.variant.priceV2.currencyCode }
    )}`;
  }, [lineItem]);

  const getImage = useCallback((): Image => {
    return {
      src: lineItem?.variant?.image?.originalSrc,
      alt: lineItem?.variant?.image?.altText,
    };
  }, [lineItem]);

  return (
    <Wrapper>
      <ItemDescWrapper>
        <img src={getImage().src} alt={getImage().alt} />
        <TextWrapper>
          <ItemTitle>{getTitle()}</ItemTitle>
          <Description>{getDescription()}</Description>
          <Price>{getPrice()}</Price>
          <CtaWrapper className="cart-item__cta-desktop">
            <QuantityBtn>
              <IconButton
                aria-label={staticLabels?.plus}
                onClick={handleDecrement}
              >
                <Icon src={MinusIcon} width={8} />
              </IconButton>
              <span>{lineItem?.quantity}</span>
              <IconButton
                aria-label={staticLabels?.minus}
                onClick={handleIncrement}
              >
                <Icon src={PlusIcon} width={8} />
              </IconButton>
            </QuantityBtn>
            <RemoveBtn
              onClick={handleRemove}
              label={labels?.remove}
              type={ButtonType.PRIMARY_DARK}
            />
          </CtaWrapper>
        </TextWrapper>
      </ItemDescWrapper>
      <CtaWrapper className="cart-item__cta-mobile">
        <QuantityBtn>
          <IconButton
            aria-label={staticLabels?.minus}
            onClick={handleDecrement}
          >
            <Icon src={MinusIcon} width={8} />
          </IconButton>
          <span>{lineItem?.quantity}</span>
          <IconButton aria-label={staticLabels?.plus} onClick={handleIncrement}>
            <Icon src={PlusIcon} width={8} />
          </IconButton>
        </QuantityBtn>
        <RemoveBtn
          label={labels?.remove}
          type={ButtonType.PRIMARY_DARK}
          onClick={handleRemove}
        />
      </CtaWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${toVWMobile(35)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(20)}vw;
  }
`;

const ItemDescWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: 0;
  }

  & img {
    height: ${toVWMobile(85)}vw;
    margin-right: ${toVWMobile(10)}vw;
    object-fit: cover;
    width: ${toVWMobile(85)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      height: ${toVWDesktop(125)}vw;
      margin-right: ${toVWDesktop(10)}vw;
      width: ${toVWDesktop(125)}vw;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-height: ${toVWDesktop(120)}vw;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &.cart-item__cta-mobile {
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      display: none;
    }
  }

  &.cart-item__cta-desktop {
    display: none;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      align-items: flex-end;
      display: flex;
      flex: 1;
      margin-top: ${toVWDesktop(10)}vw;
      width: 75%;
    }
  }
`;

const ItemTitle = styled.h2`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Description = styled.span`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toREM(28)}rem;
`;

const Price = styled(Description)`
  color: #2b6447;
`;

const RemoveBtn = styled(Button)`
  border: none;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  font-style: normal;
  height: ${toVWMobile(40)}vw;
  letter-spacing: 0.00016em;
  padding: 0 ${toVWMobile(10)}vw;
  width: 48%;
  min-width: 48%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    cursor: pointer;
    height: ${toVWDesktop(40)}vw;
    padding: 0 ${toVWDesktop(10)}vw;
  }
`;

const QuantityBtn = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: ${toVWMobile(40)}vw;
  border: 2px solid #000000;
  background-color: #ffffff;
  width: 48%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(40)}vw;
  }

  & span {
    color: #000000;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(16)}rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.00016em;
  }
`;

const Icon = styled(SVG)<{ width: number }>`
  width: ${(props) => toVWMobile(props.width)}vw;
  height: auto;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(10)}vw;
    width: ${toVWDesktop(10)}vw;
  }
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    border-radius: 50%;
    cursor: pointer;
    height: ${toVWDesktop(15)}vw;
    width: ${toVWDesktop(15)}vw;

    &:hover {
      background-color: #75c154;
    }
  }
`;

export default CartItem;
