import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import SVG from "react-inlinesvg";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import CloseIcon from "../../../assets/images/close_icon.svg";
import SearchIcon from "../../../assets/images/search_icon.svg";
import AvatarIcon from "../../../assets/images/avatar_icon.svg";
import ShoppingCartIcon from "../../../assets/images/shopping_cart_icon.svg";
import DrawerMenuItem from "./DrawerMenuItem";
import DrawerMenuItemView from "./DrawerMenuItemView";
import * as CommonHeaderTypes from "../types";
import { NavbarOptions } from "../Header";
import shopify from "../../../config/shopify";
import { ROUTES } from "../../../helpers/routes";
import { NavBarType } from "../NavBar/NavBar";
import { useAppSelector } from "../../../hooks";
import { useStaticLabels } from "../../../hooks/useStaticLabels";
import { useSettings } from "../../../hooks/useSettings";

const clientSideWindow =
  typeof window !== "undefined"
    ? window
    : {
        innerWidth: 0,
      };

export type DrawerProps = {
  open: boolean;
  onClose: () => void;
  menuItems: Array<CommonHeaderTypes.MenuItem>;
  toggleInNav: (key: NavbarOptions) => void;
};

const Drawer = ({
  open,
  onClose,
  toggleInNav,
  menuItems,
}: DrawerProps): JSX.Element => {
  const styles = useSpring({ left: !open ? -clientSideWindow.innerWidth : 0 });
  const [isSubMenuItemOpen, setSubMenuItemOpen] = useState<boolean>(false);
  const [selectedSubMenuItem, setSelectedSubMenuItem] =
    useState<CommonHeaderTypes.SubMenuItem>();
  const shopifyCheckout = useAppSelector(
    (state) => state.shopify.checkout,
    (a, b) =>
      a?.id === b?.id && a?.subtotalPrice?.amount === b?.subtotalPrice?.amount
  );
  const staticLabels = useStaticLabels();
  const isShoppifyEnabled = useSettings();

  useEffect(() => {
    if (open) {
      document.body.classList.add("stop-scrolling");
      document.getElementsByTagName("html")[0].classList.add("stop-scrolling");
      return () => {
        document.body.classList.remove("stop-scrolling");
        document
          .getElementsByTagName("html")[0]
          .classList.remove("stop-scrolling");
      };
    }
    if (!open) {
      setSelectedSubMenuItem(undefined);
    }
  }, [open]);

  return (
    <Container style={styles}>
      <Header>
        <Button onClick={onClose} aria-label={"Close Menu"}>
          <Icon src={CloseIcon} width={20} />
        </Button>
        {isShoppifyEnabled && (
          <ActionsContainer>
            <Search
              onClick={() => (onClose(), toggleInNav("search"))}
              src={SearchIcon}
              width={20.01}
              color={"#000000"}
            />
            {shopify.enableAccount && (
              <Avatar
                onClick={() => (
                  onClose(), (window.location.href = ROUTES.ACCOUNT)
                )}
                src={AvatarIcon}
                width={20.08}
                color={"#000000"}
              />
            )}
            {shopify.enableCart && (
              <CartButtonBg
                displayBadge={(shopifyCheckout?.lineItems?.length || 0) > 0}
                data-items={shopifyCheckout?.lineItems?.length || 0}
                theme={NavBarType.LIGHT}
                desktopOnly
                aria-label={staticLabels?.cart}
              >
                <Cart
                  onClick={() => (onClose(), toggleInNav("cart"))}
                  src={ShoppingCartIcon}
                  width={22.57}
                  color={"#000000"}
                />
              </CartButtonBg>
            )}
          </ActionsContainer>
        )}
      </Header>
      <HeaderPlaceholder />
      <Content>
        {menuItems &&
          menuItems.map((mItem) => (
            <>
              <DrawerMenuItem
                menuItem={mItem}
                onClickViewMore={(sMenuItem) => {
                  setSubMenuItemOpen(true);
                  setSelectedSubMenuItem(sMenuItem);
                }}
              />
            </>
          ))}
        <DrawerMenuItemView
          onBackClick={() => setSubMenuItemOpen(false)}
          subMenuItem={selectedSubMenuItem}
          open={isSubMenuItemOpen}
        />
      </Content>
    </Container>
  );
};

const NavButton = styled.button<{
  theme: "light" | "dark";
  desktopOnly?: boolean;
}>`
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: none;
  text-decoration: none;
  width: fit-content;
  height: fit-content;
`;

const CartButtonBg = styled(NavButton)<{
  displayBadge: boolean;
}>`
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    cursor: pointer;
    display: flex;
  }

  &:before {
    content: ${(props) => (props.displayBadge ? `attr(data-items)` : "none")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${toVWMobile(13)}vw;
    height: ${toVWMobile(13)}vw;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(11)}rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    position: absolute;
    top: 0%;
    left: ${vwMobile(4.5)};
  }
`;

const Container = styled(animated.nav)`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const Header = styled.div`
  width: 100%;
  height: ${toVWMobile(56)}vw;
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: ${toVWMobile(16.8)}vw ${toVWMobile(16)}vw ${toVWMobile(16.8)}vw
    ${toVWMobile(21)}vw;
`;

const HeaderPlaceholder = styled.div`
  height: ${toVWMobile(56)}vw;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: ${toVWMobile(24)}vw;
`;

const Icon = styled(SVG)<{ width: number }>`
  width: ${(props) => toVWMobile(props.width)}vw;
  height: auto;
  object-fit: contain;
`;

const Search = styled(Icon)<{ color: string }>`
  & path {
    fill: ${(props) => props.color};
  }
`;

const Avatar = styled(Icon)<{ color: string }>`
  & path {
    fill: ${(props) => props.color};
  }
`;

const Cart = styled(Avatar)``;

export default Drawer;
