import { uniqueId } from "lodash";
import React from "react";
import { useMemo } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import errorIcon from "../../../assets/images/errorIcon.svg";
import { rem, vwDesktop, vwMobile } from "../../../helpers/styles";
import { MEDIA_DESKTOP } from "../../../helpers/theme";

export const COLOR_ON_DARK = "#E17070";
export const COLOR_ON_LIGHT = "#D63D3D";

export type ErrorMessageProps = { message: string; bgType?: "light" | "dark" };

export const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
  const { message = "", bgType = "dark" } = props;
  const id = useMemo(() => uniqueId("errorMessage-"), []);

  return (
    <Error key={id} color={bgType === "dark" ? COLOR_ON_DARK : COLOR_ON_LIGHT}>
      <ErrorIcon
        src={errorIcon}
        color={bgType === "dark" ? COLOR_ON_DARK : COLOR_ON_LIGHT}
      />
      {message}
    </Error>
  );
};
export default ErrorMessage;

const ErrorIcon = styled(InlineSVG)<{ color?: string }>`
  display: inline-block;

  width: ${vwMobile(16)};
  height: ${vwMobile(16)};
  margin-right: ${vwMobile(4)};
  position: relative;
  top: ${vwMobile(-2)};

  circle {
    fill: ${(p) => p.color};
  }

  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(-2)};
    width: ${vwDesktop(16)};
    height: ${vwDesktop(16)};
    margin-right: ${vwDesktop(4)};
  }
`;

const Error = styled.div<{ color: string }>`
  font: 800 ${rem(14)} / ${rem(14)} ${props => props.theme.fontFamily}, sans-serif;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  margin-block: ${vwMobile(11)};
  color: ${(p) => p.color};
  ${MEDIA_DESKTOP} {
    margin-block: ${vwDesktop(11)};
  }
`;
