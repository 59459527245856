import React, { forwardRef } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import CloseIcon from "../../../assets/images/close_icon.svg";
import SearchIcon from "../../../assets/images/search_icon.svg";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";

export type SearchInputProps = {
  value: string;
  onClose: () => void;
  onChange: (value: string) => void;
  autoFocus: boolean;
  placeHolder: string;
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(props: SearchInputProps, ref) {
    const { value, onClose, onChange, ...rest } = props;

    return (
      <Container>
        <Icon src={SearchIcon} size={28} color="black" />
        <Input
          ref={ref}
          type="text"
          value={value}
          placeholder="search..."
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
        <Icon src={CloseIcon} size={24} onClick={onClose} />
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${toVWMobile(18)}vw;
  padding: ${toVWMobile(10)}vw 0;
  border-bottom: ${toVWMobile(3)}vw solid #000000;
  color: #000000;

  width: initial;
  margin: auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(610)}vw;
    gap: ${toVWDesktop(18)}vw;
    padding: ${toVWDesktop(10)}vw 0;
    border-bottom: ${toVWDesktop(3)}vw solid #000000;
  }
`;

const Input = styled.input`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 800;
  outline: none;
  border: none;
  line-height: ${toREM(18)}rem;
  height: ${toREM(47)}rem;
  width: 100%;

  &::placeholder {
    opacity: 0.4;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
  }
`;

const Icon = styled(SVG)<{ color?: string; size: number }>`
  width: ${(props) => toVWMobile(props.size)}vw;
  height: ${(props) => toVWMobile(props.size)}vw;
  object-fit: contain;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${(props) => toVWDesktop(props.size)}vw;
    height: ${(props) => toVWDesktop(props.size)}vw;
  }

  & path {
    fill: ${(props) => props.color};
  }
`;

export default SearchInput;
