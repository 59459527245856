import React, { FC } from "react";

import { Helmet } from "react-helmet";
import useIntl from "../../hooks/useIntl";

export enum OG_TYPES {
  website = "website",
  article = "article",
}

export type SEOOGTags = {
  og_title?: string;
  og_description?: string;
  og_image?: string;
  og_type?: OG_TYPES;
};

export type SEOBase = {
  title: string;
  description: string;
  withChatBot?: boolean;
  loadAdimo?: boolean;
};

export type SEOProps = SEOBase & SEOOGTags;

const SEO: FC<SEOProps> = ({
  title,
  description,
  og_title,
  og_description,
  og_image,
  og_type,
  withChatBot,
  loadAdimo,
}: SEOProps) => {
  const intl = useIntl();
  return (
    <Helmet
      htmlAttributes={{
        lang: intl?.locale,
      }}
      defer={false}
    >
      <meta charSet="utf-8" />
      {withChatBot && <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=ac8005eb-c8d4-4aff-bd72-d96122b41195"> </script>}
      {loadAdimo && <script src="https://cdn.adimo.co/scripts/lightboxv2.min.js"></script>}
      {loadAdimo && <script src="https://cdn.adimo.co/scripts/priceFirst.min.js"></script>}
      {loadAdimo && <script src="https://cdn.adimo.co/scripts/iframev3.min.js"></script>}
      <title>{title}</title>
      <meta name="description" content={description} />
      {og_title && <meta property="og:title" content={og_title} />}
      {og_description && (
        <meta property="og:description" content={og_description} />
      )}
      {og_image && <meta property="og:image" content={og_image} />}
      {og_type && <meta property="og:type" content={og_type} />}
    </Helmet>
  );
};

export default SEO;
