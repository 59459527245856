import { getTranslations } from "./translations";
import { getAssetURL } from "./assets";
import { SEOProps, SEOOGTags } from "../components/SEO/SEO";
import { query as staticQuery } from "../hooks/staticQueries/useSeoStaticQuery";

export const parseSEO = (
  data = {},
  lang: string,
  overrides?: SEOOGTags,
  seoPath = "seo_information.translations"
): SEOProps => {
  const translatedSEO = getTranslations(data, seoPath, lang);

  return {
    title: translatedSEO?.title || "",
    description: translatedSEO?.description || "",
    og_image: getAssetURL(translatedSEO?.og_image) || overrides?.og_image,
    og_title:
      translatedSEO?.og_title || overrides?.og_title || translatedSEO?.title,
    og_description:
      translatedSEO?.og_description ||
      overrides?.og_description ||
      translatedSEO?.description,
    og_type: translatedSEO?.og_type || overrides?.og_type || "website",
  };
};

export const query = staticQuery;
