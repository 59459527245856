import gql from "graphql-tag";

export const RESET_BY_URL_MUTATION = gql`
  mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const RESET_MUTATION = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
export const LOGIN_MUTATION = gql`
  mutation Login($email: String = "", $password: String = "") {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const RECOVER_MUTATION = gql`
  mutation MyMutation($email: String = "") {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
export const REGISTER_MUTATION = gql`
  mutation Register(
    $email: String = ""
    $password: String = ""
    $firstName: String = ""
    $lastName: String = ""
  ) {
    customerCreate(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      customer {
        id
        email
        firstName
        lastName
        displayName
        createdAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const LINE_ITEM = `
  id
  unitPrice{
    amount
    currencyCode
  }
  quantity
  variant{
    id
    title
    sku
    priceV2{
        amount
        currencyCode
    }
    image{
        originalSrc
        altText
    }
    product{
        title
        images(first: 1) {
          edges {
            node {
              originalSrc
              altText
            }
          }
        }
    }
  }
`;

const CHECKOUT = `
        webUrl
        currencyCode
        orderStatusUrl
        email
        id
        subtotalPriceV2{
          amount
          currencyCode
        }
        lineItems(first: 20){
          edges{
            node{
                ${LINE_ITEM}
            }
          }
        }
`;

export const CREATE_CHECKOUT = gql`
  mutation createCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const ASSOCIATE_CUSTOMER_WITH_CHECKOUT = gql`
  mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const ADD_LINE_ITEMS = gql`
  mutation addLineItems(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const UPDATE_LINE_ITEMS = gql`
mutation updateLineItems($lineItems:[CheckoutLineItemUpdateInput!]!, $checkoutId: ID!) {
  checkoutLineItemsUpdate(lineItems: $lineItems, checkoutId: $checkoutId) {
    checkout {
        ${CHECKOUT}
    }
  }
}`;

export const UPDATE_NOTE = gql`
  mutation updateNotes($note: String!, $checkoutId: ID!) {
    checkoutAttributesUpdate(checkoutId: $checkoutId, input: {note: $note}) {
      checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const REMOVE_LINE_ITEMS = gql`
  mutation removeLineItems($lineItemIds: [ID!]!, $checkoutId: ID!) {
    checkoutLineItemsRemove(
      lineItemIds: $lineItemIds
      checkoutId: $checkoutId
    ) {
      checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const GET_NODE = gql`
  query getNode($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        ${CHECKOUT}
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query product($query: String) {
    products(query: $query, first: 100) {
      edges {
        node {
          id
          title
          description
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          variants(first: 100) {
            edges {
              node {
                id
                availableForSale
                quantityAvailable
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                image {
                  originalSrc
                  altText
                }
                sku
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CREATE_CUSTOMER_TOKEN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query Customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      email
      firstName
      id
      lastName
      addresses(first: 100) {
        edges {
          node {
            address1
            address2
            city
            company
            country
            province
            firstName
            id
            lastName
            name
            phone
            zip
          }
        }
      }
      defaultAddress {
        address1
        address2
        city
        company
        country
        province
        firstName
        id
        lastName
        name
        phone
        zip
      }
      orders(first: 50) {
        edges {
          cursor
          node {
            cancelReason
            canceledAt
            currencyCode
            currentSubtotalPrice {
              amount
              currencyCode
            }
            currentTotalDuties {
              amount
              currencyCode
            }
            currentTotalPrice {
              amount
              currencyCode
            }
            currentTotalTax {
              amount
              currencyCode
            }
            customerLocale
            customerUrl
            discountApplications(first: 20) {
              edges {
                node {
                  allocationMethod
                  targetSelection
                  targetType
                  value {
                    __typename
                  }
                }
              }
            }
            edited
            email
            financialStatus
            fulfillmentStatus
            id
            lineItems(first: 20) {
              edges {
                node {
                  quantity
                  title
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  variant {
                    id
                    title
                    sku
                    priceV2 {
                      amount
                      currencyCode
                    }
                    image {
                      originalSrc
                      altText
                    }
                    product {
                      title
                      images(first: 1) {
                        edges {
                          node {
                            originalSrc
                            altText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            name
            orderNumber
            phone
            processedAt
            shippingAddress {
              address1
              address2
              city
              company
              country
              firstName
              id
              lastName
              name
              phone
              province
              zip
            }
            statusUrl
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalRefundedV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation CustomerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        address1
        address2
        city
        company
        country
        firstName
        id
        lastName
        name
        phone
        zip
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation CustomerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      deletedCustomerAddressId
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_DEFAULT_ADDRESS = gql`
  mutation CustomerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        defaultAddress {
          address1
          address2
          city
          company
          country
          firstName
          id
          lastName
          name
          phone
          province
          zip
        }
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation CustomerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        message
        field
      }
    }
  }
`;
