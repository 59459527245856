import { useCallback, useEffect, useMemo } from "react";
import { ShopifyService } from "../services/shopify/shopify";
import { useAppDispatch } from "./useAppDispatch";
import { addProduct } from "../store/shopify";
import { useAppSelector } from "./useAppSelector";
import { Product } from "../services/shopify/types";

export const useShopifyProduct = (productNumberId: string): Product | null => {
  const dispatch = useAppDispatch();
  const shopifyProducts = useAppSelector(
    (state) => state.shopify.productsByProductNumberId,
    (a, b) =>
      JSON.stringify(Object.keys(a).sort()) ===
      JSON.stringify(Object.keys(b).sort())
  );

  const getProduct = useCallback(async (pNumberId: string) => {
    const product = await ShopifyService.getProduct(pNumberId);
    dispatch(addProduct({ product, productNumberId: pNumberId }));
  }, []);

  useEffect(() => {
    if (productNumberId) {
      try {
        getProduct(productNumberId);
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const obj = useMemo(() => {
    if (!shopifyProducts || !productNumberId) {
      return null;
    }
    return shopifyProducts[productNumberId];
  }, [shopifyProducts, productNumberId]);

  return obj || null;
};
