import { animated, useTransition } from "@react-spring/web";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { toREM, toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import ResultItem from "./ResultItem";
import SearchInput from "./SearchInput";
import { VariantCard } from "../../ShopOurPacks/ShopCard";
import usePacks from "../../../hooks/usePacks";

export type SearchBarProps = {
  show: boolean;
  onClose: () => void;
  resultMessage?: string;
  inputPlaceholder?: string;
};

function SearchBar({
  show = false,
  onClose,
  resultMessage = "results",
  inputPlaceholder = "search...",
}: SearchBarProps): JSX.Element {
  const [queryString, setQueryString] = useState("");

  const packs = usePacks();

  const filteredVariants: VariantCard[] = useMemo(() => {
    const filtered: VariantCard[] = [];

    if (packs && queryString) {
      packs.forEach((pack: any) => {
        pack.variants.forEach((variant: VariantCard) => {
          if (variant.title.toLocaleLowerCase().includes(queryString))
            filtered.push(variant);
        });
      });
    }

    return filtered;
  }, [queryString, packs]);

  const inputRef = useRef<HTMLInputElement>(null);
  const searchInput = inputRef.current;

  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    config: {
      duration: 100,
    },
  });

  useEffect(() => {
    show && searchInput?.focus();
  }, [show, searchInput]);

  return transitions(
    (styles, item) =>
      item && (
        <Container style={styles}>
          <SearchInput
            ref={inputRef}
            value={queryString}
            onClose={() =>
              !queryString.length
                ? onClose()
                : (setQueryString(""), searchInput?.focus())
            }
            onChange={(v) => setQueryString(v)}
            placeHolder={inputPlaceholder}
            autoFocus
          />
          {filteredVariants.length > 0 && (
            <>
              <ResultMessage>
                {filteredVariants.length} {resultMessage}
              </ResultMessage>
              <ResultList>
                {filteredVariants.map((variant: VariantCard, index: number) => (
                  <ResultItem key={String(index)} item={variant} />
                ))}
              </ResultList>
            </>
          )}
        </Container>
      )
  );
}

const Container = styled(animated.div)`
  position: fixed;
  z-index: 11;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;

  width: 100%;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw;

  background: white;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(64)}vw;
    min-height: ${toVWDesktop(533)}vw;
    padding: ${toVWDesktop(40)}vw ${toVWDesktop(24)}vw;
  }
`;

const ResultList = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${toVWMobile(21)}vw;

  > * {
    flex-shrink: 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${toVWDesktop(21)}vw;
    overflow-x: auto;
    flex-flow: row nowrap;
    max-width: ${toVWDesktop(1030)}vw;
    margin: auto;
    padding-bottom: ${toVWDesktop(20)}vw;
  }
`;

const ResultMessage = styled.span`
  color: #2b6447;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin: ${toVWMobile(30)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toVWDesktop(24)}vw;
    margin: ${toVWDesktop(30)}vw 0;
  }
`;
export default SearchBar;
