export const FONTS = {
  NAME_SANS_DISPLAY_TRIAL: "Name Sans Display Trial",
  NAME_SANS_HEADING_TRIAL: "Name Sans Heading Trial",
  SHARP_SANS_DISPLAY_NO1: "Sharp Sans Display No1",
};

export const BREAKPOINTS = {
  DESKTOP: 769,
};

export const MEDIA = {
  DESKTOP: `(min-width: ${BREAKPOINTS.DESKTOP}px)`,
  MOBILE: `(max-width: ${BREAKPOINTS.DESKTOP}px)`,
};

export const MEDIA_DESKTOP = `@media ${MEDIA.DESKTOP}`;

export const MEDIA_MOBILE = `@media ${MEDIA.MOBILE}`;
