import * as React from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { Link } from "../../shared/Link";
import MinusIcon from "../../../assets/images/minus_icon.svg";
import PlusIcon from "../../../assets/images/plus_icon.svg";
import TriangleIcon from "../../../assets/images/triangle_icon.svg";
import { toREM, toVWMobile } from "../../../helpers/styles";
import {} from "../../../helpers/theme";
import * as CommonHeaderTypes from "../types";

export type DrawerMenuItemProps = {
  onClickViewMore: (subMenuItem: CommonHeaderTypes.SubMenuItem) => void;
  menuItem: CommonHeaderTypes.MenuItem;
};

const DrawerMenuItem = ({ onClickViewMore, menuItem }: DrawerMenuItemProps) => {
  const hasSubMenuItems =
    menuItem?.subMenuItems && menuItem?.subMenuItems?.length > 0;

  const hasOptions = (item: any) => {
    return item?.options?.length > 0;
  };

  return (
    <Collapsible
      easing={"ease-in"}
      transitionTime={300}
      triggerDisabled={!hasSubMenuItems}
      trigger={
        <MainMenuItem to={menuItem?.link || undefined}>
          <MainMenuItemText>{menuItem?.label}</MainMenuItemText>
          <MainMenuItemAction>
            {hasSubMenuItems && <MainMenuIcon src={PlusIcon} alt={"Expand"} />}
          </MainMenuItemAction>
        </MainMenuItem>
      }
      triggerWhenOpen={
        <MainMenuItem to={menuItem?.link || undefined}>
          <MainMenuItemText>{menuItem?.label}</MainMenuItemText>
          <MainMenuItemAction>
            <MainMenuIcon src={MinusIcon} alt={"Collapse"} />
          </MainMenuItemAction>
        </MainMenuItem>
      }
    >
      {hasSubMenuItems &&
        menuItem?.subMenuItems.map(
          (sMenuItem: CommonHeaderTypes.SubMenuItem) => (
            <>
              <SecondaryMenuItem
                to={
                  hasOptions(sMenuItem)
                    ? undefined
                    : sMenuItem?.link || undefined
                }
                onClick={() => onClickViewMore(sMenuItem)}
              >
                <SecondaryMenuItemText>
                  {sMenuItem?.label}
                </SecondaryMenuItemText>
                <MainMenuItemAction>
                  <SecondaryMenuIcon src={TriangleIcon} alt={"Go"} />
                </MainMenuItemAction>
              </SecondaryMenuItem>
            </>
          )
        )}
    </Collapsible>
  );
};

const MainMenuItem = styled(Link)`
  width: 100%;
  padding: ${toVWMobile(10)}vw ${toVWMobile(23)}vw ${toVWMobile(10)}vw
    ${toVWMobile(20)}vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #000000;
  -webkit-tap-highlight-color: transparent;
`;

const MainMenuItemText = styled.div`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  line-height: ${toREM(31)}rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

const MainMenuItemAction = styled.button`
  border: none;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(20)}rem;
  font-weight: 800;
  letter-spacing: 0.02em;
  background-color: transparent;
`;

const MainMenuIcon = styled.img`
  object-fit: contain;
  width: ${toVWMobile(14)}vw;
  height: auto;
`;

const SecondaryMenuItem = styled(Link)`
  width: 100%;
  padding: ${toVWMobile(11)}vw ${toVWMobile(22)}vw ${toVWMobile(11)}vw
    ${toVWMobile(28)}vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: #000000;
`;

const SecondaryMenuItemText = styled.div`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 800;
  line-height: ${toREM(24)}rem;
  letter-spacing: 0.02em;
`;

const SecondaryMenuIcon = styled.img`
  object-fit: contain;
  width: ${toVWMobile(6)}vw;
  height: auto;
  transform: ${(props) =>
    props.theme.isRTL ? `rotate(180deg)` : `rotate(0deg)`};
`;

export default DrawerMenuItem;
