import { useIntl } from "./useIntl";
import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { MailingListProps } from "../components/MailingList";
import { useMailingStaticQuery } from "../hooks/staticQueries/useMailingStaticQuery";

export const useMailingData = (): MailingListProps => {
  const intl = useIntl();
  const data = useMailingStaticQuery();

  const mailingData = useMemo<MailingListProps>((): MailingListProps => {
    const translatedData = getTranslations(
      data,
      "directus.mailing_list.translations",
      intl.locale
    );

    return {
      mainTitle: translatedData?.main_title,
      description: translatedData?.description,
      inputPlaceholder: translatedData?.input_placeholder,
      buttonLabel: translatedData?.button_label,
      klaviyoListId: translatedData?.klaviyo_list_id,
      termsAndConditions: translatedData?.disclaimer,
      successMessage: translatedData?.success_message,
      checkbox: translatedData?.enable_checkbox,
    };
  }, [data, intl]);

  return mailingData;
};
