import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import NavBar, { NavBarAnimations, NavBarType } from "./NavBar/NavBar";
import MobileDrawer from "./MobileDrawer/Drawer";
import DesktopDrawer from "./DesktopDrawer/Drawer";
import CartDrawer from "./CartDrawer/CartDrawer";
import { toVWDesktop, toVWMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import * as CommonHeaderTypes from "./types";
import { NavbarOptions } from "./types";
import { useHeaderData } from "../../hooks/useHeaderData";
import SearchBar from "./SearchBar/SearchBar";
import { useEnv } from "../../hooks";
import { ENV } from "../../hooks/useEnv";
import { ROUTES } from "../../helpers/routes";
import { useSettings } from "../../hooks/useSettings";

/**
 In Progress Component, it was committed so other devs can see how to properly
 handle svg icons in the projects
*/

enum ViewPorts {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export type NavbarState = {
  show: NavbarOptions;
};
const navbarContextValues: NavbarState = {
  show: NavbarOptions.empty,
};

type HeaderProps = {
  backgroundColor?: string;
};

const Header = memo(
  ({ backgroundColor = "#033305" }: HeaderProps): JSX.Element => {
    const data = useHeaderData();

    const [menuItems, setMenuItems] = useState<
      Array<CommonHeaderTypes.MenuItem>
    >([]);

    const [selectedMenuItem, setSelectedMenuItem] = useState<
      CommonHeaderTypes.MenuItem | undefined
    >();

    const [open, setOpen] = useState<{ mobile: boolean; desktop: boolean }>({
      mobile: false,
      desktop: false,
    });

    const [loaded, setLoaded] = useState<boolean>(false);
    const [env] = useEnv();

    const [navBarAnimation, setNavBarAnimation] = useState<
      NavBarAnimations | undefined
    >(NavBarAnimations.OUT);

    const skipNavBarAnimation = useRef<boolean>(true);

    const [navbarState, setNavbarState] = useState(navbarContextValues);

    const isTicking = useRef<boolean>(false);
    const selectedMenuItemRef = useRef<CommonHeaderTypes.MenuItem | undefined>(
      null
    );

    const isShoppifyEnabled = useSettings();

    const handleScroll = useCallback(() => {
      const lastKnownScrollPosition = window.scrollY;

      if (!isTicking.current) {
        window.requestAnimationFrame(function () {
          skipNavBarAnimation.current = false;
          if (lastKnownScrollPosition > 0) {
            setNavBarAnimation(NavBarAnimations.IN);
          } else {
            !selectedMenuItemRef?.current &&
              setNavBarAnimation(NavBarAnimations.OUT);
          }
          isTicking.current = false;
        });

        isTicking.current = true;
      }
    }, [setNavBarAnimation]);

    const toggleMenu = useCallback(
      (open: boolean, viewPort: ViewPorts) => {
        const tId = setTimeout(() => {
          setOpen({
            desktop: viewPort === ViewPorts.DESKTOP ? open : false,
            mobile: viewPort === ViewPorts.MOBILE ? open : false,
          });
          clearTimeout(tId);
        }, 0);

        if (viewPort === ViewPorts.DESKTOP && open) {
          setNavBarAnimation(NavBarAnimations.IN);
        }
      },
      [setOpen, setNavBarAnimation]
    );

    const handleMenuItemClick = useCallback(
      (menuItem: CommonHeaderTypes.MenuItem | undefined) => {
        skipNavBarAnimation.current = window.scrollY === 0;
        setSelectedMenuItem(menuItem);
        toggleMenu(true, ViewPorts.DESKTOP);
      },
      []
    );

    const toggleInNav = (key: NavbarOptions) => {
      setNavbarState((p) => {
        const show = p.show === key ? "" : key;
        return { ...p, show };
      });
    };
    const toggleSearch = () => toggleInNav(NavbarOptions.search);
    const handleAvatarClick = () => (window.location.href = ROUTES.ACCOUNT);
    const toggleCart = () => toggleInNav(NavbarOptions.cart);

    useEffect(() => {
      selectedMenuItemRef.current = selectedMenuItem;
    }, [selectedMenuItem]);

    useEffect(() => {
      setMenuItems(data?.menuItems);
    }, [data]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    useLayoutEffect(() => {
      setLoaded(true);
    }, []);

    const shouldDisplayWidgets = useMemo(() => {
      return env === ENV.CLIENT && loaded;
    }, [loaded, env]);

    return (
      <Container
        onMouseLeave={() => {
          skipNavBarAnimation.current = true;
          toggleMenu(false, ViewPorts.DESKTOP);
          setSelectedMenuItem(undefined);
          if (window.scrollY === 0) {
            setNavBarAnimation(NavBarAnimations.OUT);
          }
        }}
      >
        <FixedGreenNavBar
          immediate={skipNavBarAnimation?.current}
          animation={navBarAnimation}
          backgroundColor={backgroundColor}
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          type={NavBarType.DARK}
          onMenuClick={() => toggleMenu(true, ViewPorts.MOBILE)}
          onMenuItemClick={handleMenuItemClick}
          onSearchClick={toggleSearch}
          onAvatarClick={handleAvatarClick}
          onCartClick={toggleCart}
          onMouseEnterSearch={() => handleMenuItemClick(undefined)}
          onMouseEnterAvatar={() => handleMenuItemClick(undefined)}
          onMouseEnterCart={() => handleMenuItemClick(undefined)}
          navState={navbarState}
          id="theNavBar"
        />
        <HeaderPlaceholder />
        {shouldDisplayWidgets && (
          <>
            {isShoppifyEnabled && (
              <>
                <SearchBar
                  show={navbarState.show === NavbarOptions.search}
                  onClose={toggleSearch}
                />
                <CartDrawer
                  isOpen={navbarState.show === NavbarOptions.cart}
                  onClose={toggleCart}
                />
              </>
            )}
            <MobileDrawer
              menuItems={menuItems}
              open={open?.mobile}
              onClose={() => {
                toggleMenu(false, ViewPorts.MOBILE);
              }}
              {...{ toggleInNav }}
            />

            <FixedDesktopDrawer
              open={open?.desktop}
              selectedMenuItem={selectedMenuItem}
              onClose={() => {
                skipNavBarAnimation.current = false;
                toggleMenu(false, ViewPorts.DESKTOP);
                setSelectedMenuItem(undefined);
                if (window.scrollY === 0) {
                  setNavBarAnimation(NavBarAnimations.OUT);
                }
              }}
            />
          </>
        )}
      </Container>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.backgroundColor === nextProps.backgroundColor;
  }
);

const Container = styled.div`
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  background: transparent;
`;

const FixedGreenNavBar = styled(NavBar)`
  position: fixed;
  top: 0;
  z-index: 9;
`;

const FixedDesktopDrawer = styled(DesktopDrawer)`
  position: fixed;
  z-index: 10;
  top: ${toVWMobile(56)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(64)}vw;
  }
`;

const HeaderPlaceholder = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: ${toVWMobile(56)}vw;
  background: ${(props) => props.backgroundColor};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(64)}vw;
  }
`;

export default Header;
