import React, { useEffect } from "react";

import useScript from "../../hooks/useScript";

const safeJsonParse = (
  data: string | Record<string, any>,
) => {
  try {
    return JSON.parse(data as string);
  } catch {
    return data;
  }
};

const getDomain = () => {
  const url =
    process.env.GATSBY_WEBSITE_URL ?? "beyondmeat.com";

  return url
    .replace(/(^\w+:|^)\/\//, "")
    .replace("www.", "")
    .replace(/:\d+$/, "");
};

const Cookies = (): JSX.Element => {
  useScript(
    `https://consent.trustarc.com/notice?domain=${getDomain()}&c=teconsent&noticeType=bb&js=nj&text=true&gtm=1`,
  );
  useEffect(() => {
    if (typeof window !== "undefined") {
      const __dispatched__: { [key: string]: number } = {};
      const self = window as any;

      const handleMessage = (e: any) => {
        let d = null;
        try {
          if (
            e.data &&
            (d = safeJsonParse(e.data)) &&
            (d = d.PrivacyManagerAPI) &&
            d.capabilities &&
            d.action == "getConsentDecision"
          ) {
            const newDecision =
              self.PrivacyManagerAPI.callApi(
                "getGDPRConsentDecision",
                self.location.host,
              ).consentDecision;
            newDecision &&
              newDecision.forEach(function (label: string) {
                if (!__dispatched__[label]) {
                  self.dataLayer &&
                    self.dataLayer.push({
                      event: "GDPR Pref Allows " + label,
                    });
                  __dispatched__[label] = 1;
                }
              });
          }
        } catch (xx) {
          /** not a cm api message **/
        }
      };

      window.addEventListener("message", handleMessage);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener(
          "message",
          handleMessage,
        );
      };
    }
  }, []);
  return (
    <>
      <div id="consent_blackbar"></div>
    </>
  );
};

export default Cookies;
