const isExternalLink = (to: string): boolean => {
  return (
    to?.startsWith("https") ||
    to?.startsWith("http") ||
    to?.startsWith("mailto")
  );
};

export const getLink = (
  to: string,
  locale: string = process.env.GATSBY_ACTIVE_LOCALE as string
): string => {
  if (!to) {
    return "";
  }
  if (isExternalLink(to)) {
    return to;
  }
  if (to?.includes(locale)) {
    return to;
  }
  let origin = "";
  if (typeof window !== "undefined") {
    origin = window.location.origin;
  }

  if (to?.startsWith("/")) {
    return `${origin}/${locale}${to}`;
  }
  return `${origin}/${locale}/${to}`;
};

export const openLink = (link: string) => {
  window.open(link, "_self");
};
