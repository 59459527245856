import * as React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Link } from "../../shared/Link";
import {  } from "../../../helpers/theme";
import { toREM, toVWDesktop } from "../../../helpers/styles";
import * as CommonHeaderTypes from "../types";

export type DrawerSubMenuItemProps = {
  className?: string;
  onClick: (subMenuItem: CommonHeaderTypes.SubMenuItem) => void;
  subMenuItem: CommonHeaderTypes.SubMenuItem;
  active: boolean;
};

const DrawerSubMenuItem = ({
  className,
  onClick,
  active,
  subMenuItem,
}: DrawerSubMenuItemProps) => {
  const styles = useSpring({ width: active ? "100%" : "0%" });
  return (
    <Container
      className={className}
      active={active}
      onClick={() => onClick(subMenuItem)}
      onMouseEnter={() => onClick(subMenuItem)}
      to={subMenuItem?.link || undefined}
    >
      {subMenuItem.label}
      {active && <Bar style={styles} />}
    </Container>
  );
};

const Container = styled(Link)<{ active?: boolean }>`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: ${(props) => (props.active ? "#000000" : "#012102")};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 800;
  letter-spacing: 0;
  margin-top: ${toVWDesktop(7)}vw;
  margin-bottom: ${toVWDesktop(7)}vw;
  min-height: ${toREM(25)}rem;
  text-transform: uppercase;
  width: fit-content;
  text-decoration: none;
`;

const Bar = styled(animated.div)`
  width: 0;
  height: 3px;
  background-color: #2b6447;
`;

export default DrawerSubMenuItem;
