import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import axios from "axios";
import fetch from "cross-fetch";
import {
  ADD_LINE_ITEMS,
  ASSOCIATE_CUSTOMER_WITH_CHECKOUT,
  CREATE_ADDRESS,
  CREATE_CHECKOUT,
  CREATE_CUSTOMER_TOKEN,
  DELETE_ADDRESS,
  GET_CUSTOMER,
  GET_NODE,
  GET_PRODUCT,
  LOGIN_MUTATION,
  LOGOUT,
  RECOVER_MUTATION,
  REGISTER_MUTATION,
  REMOVE_LINE_ITEMS,
  RESET_BY_URL_MUTATION,
  UPDATE_ADDRESS,
  UPDATE_DEFAULT_ADDRESS,
  UPDATE_LINE_ITEMS,
  UPDATE_NOTE,
} from "./queries";
import {
  Checkout,
  Customer,
  CustomerAccessToken,
  CustomerUserError,
  LineItem,
  LineItemFull,
  Product,
  Variant,
} from "./types";

interface ShopifyServiceInterface {
  createAccount: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<
    | {
        customer: Customer;
        customerUserErrors: CustomerUserError[];
      }
    | undefined
  >;
  customerAccessTokenCreate: (
    email: string,
    password: string
  ) => Promise<{
    customerAccessToken: CustomerAccessToken | null;
    customerUserErrors: CustomerUserError[];
  } | null>;
  customerRecover: (email: string) => Promise<
    | {
        customerUserErrors: CustomerUserError[];
      }
    | undefined
  >;
  createCheckout: (lineItems?: LineItem[], email?: string) => Promise<Checkout>;
  associateCheckoutWithCustomer: (
    accessToken: string,
    checkoutId: string
  ) => Promise<Checkout>;
  getCheckout: (checkoutId: string) => Promise<Checkout>;
  addLineItems: (
    checkoutId: string,
    lineItems: LineItem[]
  ) => Promise<Checkout>;
  removeLineItems: (
    checkoutId: string,
    lineItemsId: string[]
  ) => Promise<Checkout>;
  updateLineItems: (
    checkoutId: string,
    lineItems: LineItem[]
  ) => Promise<Checkout>;
  updateNotes: (checkoutId: string, note: string) => Promise<Checkout>;
  createCustomer?: (customer: Customer) => Promise<Customer>;
  customerReset: (
    password: string,
    resetUrl: string
  ) => Promise<
    | {
        customer: { id: string };
        customerAccessToken: CustomerAccessToken;
        customerUserErrors: CustomerUserError[];
      }
    | undefined
  >;

  getProduct: (productNumberId: string) => Promise<Product>;
  getCustomer: (customerAccessToken: string) => Promise<Customer>;
  getMultipassLoginUrl: (
    customerAccessToken: string,
    returnTo: string
  ) => Promise<string>;
}

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GATSBY_SHOPIFY_STOREFRONT_URL,
    fetch,
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env
        .GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN as string,
    },
  }),
  cache: new InMemoryCache(),
});

export const ShopifyService: ShopifyServiceInterface = {
  createAccount: async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const { data } = await client.mutate<{
      customerCreate: {
        customer: Customer;
        customerUserErrors: CustomerUserError[];
      };
    }>({
      mutation: REGISTER_MUTATION,
      variables: {
        email,
        password,
        firstName,
        lastName,
      },
    });

    return data?.customerCreate;
  },
  customerAccessTokenCreate: async (email: string, password: string) => {
    const { data } = await client.mutate<{
      customerAccessTokenCreate: {
        customerAccessToken: CustomerAccessToken | null;
        customerUserErrors: CustomerUserError[];
      };
    }>({
      mutation: LOGIN_MUTATION,
      variables: {
        email,
        password,
      },
    });

    return data?.customerAccessTokenCreate ?? null;
  },
  customerRecover: async (email: string) => {
    const { data } = await client.mutate<{
      customerRecover: {
        customerUserErrors: CustomerUserError[];
      };
    }>({
      mutation: RECOVER_MUTATION,
      variables: {
        email,
      },
    });

    return data?.customerRecover;
  },

  customerReset: async (password: string, resetUrl: string) => {
    const { data } = await client.mutate<{
      customerResetByUrl: {
        customer: { id: string };
        customerAccessToken: CustomerAccessToken;
        customerUserErrors: CustomerUserError[];
      };
    }>({
      mutation: RESET_BY_URL_MUTATION,
      variables: {
        password,
        resetUrl,
      },
    });

    return data?.customerResetByUrl;
  },
  createCheckout: async (lineItems, email) => {
    const { data } = await client.mutate({
      mutation: CREATE_CHECKOUT,
      variables: {
        input: {
          email,
          lineItems,
        },
      },
    });
    const checkout = data.checkoutCreate.checkout;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      orderStatusUrl: checkout?.orderStatusUrl,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  associateCheckoutWithCustomer: async (customerAccessToken, checkoutId) => {
    if (!customerAccessToken || !checkoutId) {
      throw new Error("Invalid arguments to associate customer with checkout");
    }
    const { data } = await client.mutate({
      mutation: ASSOCIATE_CUSTOMER_WITH_CHECKOUT,
      variables: {
        customerAccessToken,
        checkoutId,
      },
    });
    const checkout = data.checkoutCustomerAssociateV2.checkout;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  getCheckout: async (checkoutId) => {
    const { data } = await client.query({
      query: GET_NODE,
      variables: {
        id: checkoutId,
      },
    });
    const checkout = data.node;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      orderStatusUrl: checkout?.orderStatusUrl,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  addLineItems: async (checkoutId, lineItems) => {
    const { data } = await client.mutate({
      mutation: ADD_LINE_ITEMS,
      variables: {
        checkoutId,
        lineItems,
      },
    });
    const checkout = data.checkoutLineItemsAdd.checkout;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  removeLineItems: async (checkoutId, lineItemIds) => {
    const { data } = await client.mutate({
      mutation: REMOVE_LINE_ITEMS,
      variables: {
        checkoutId,
        lineItemIds,
      },
    });
    const checkout = data.checkoutLineItemsRemove.checkout;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  updateLineItems: async (checkoutId, lineItems) => {
    const { data } = await client.mutate({
      mutation: UPDATE_LINE_ITEMS,
      variables: {
        checkoutId,
        lineItems,
      },
    });
    const checkout = data.checkoutLineItemsUpdate.checkout;
    return {
      id: checkout?.id,
      webUrl: checkout?.webUrl,
      currencyCode: checkout?.currencyCode,
      email: checkout?.email,
      subtotalPrice: checkout?.subtotalPriceV2,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  updateNotes: async (checkoutId, note) => {
    const { data } = await client.mutate({
      mutation: UPDATE_NOTE,
      variables: {
        checkoutId,
        note,
      },
    });
    const checkout = data?.checkoutAttributesUpdate?.checkout ?? {};
    return {
      ...checkout,
      subtotalPrice: checkout?.subtotalPriceV2,
      lineItems: checkout?.lineItems?.edges?.map(
        (e: any) => e.node as LineItemFull
      ),
    };
  },
  getProduct: async (productNumberId: string) => {
    const { data } = await client.query({
      query: GET_PRODUCT,
      variables: {
        query: productNumberId,
      },
    });
    const products = data.products.edges;
    const product = products[0]?.node;
    return {
      id: product?.id,
      description: product?.description,
      priceRange: product?.priceRange,
      variants: product?.variants?.edges?.map(
        ({ node: variant }) =>
          ({
            id: variant?.id,
            priceV2: variant?.priceV2,
            compareAtPriceV2: variant?.compareAtPriceV2,
            sku: variant.sku,
            availableForSale: variant?.availableForSale,
            quantityAvailable: variant?.quantityAvailable,
            image: variant?.image,
            product: {
              title: product?.title,
            },
          } as Variant)
      ),
    };
  },
  createCustomerAccessToken: async (email: string, password: string) => {
    try {
      const {
        data: { customerAccessTokenCreate },
      } = await client.mutate({
        mutation: CREATE_CUSTOMER_TOKEN,
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      return customerAccessTokenCreate.customerAccessToken;
    } catch (error) {
      console.log(error);
      return {
        accessToken: null,
      };
    }
  },
  createAddress: async (customerAccessToken, address) => {
    try {
      const {
        data: { customerAddressCreate },
      } = await client.mutate({
        mutation: CREATE_ADDRESS,
        variables: {
          customerAccessToken,
          address,
        },
      });
      return customerAddressCreate;
    } catch (error) {
      console.log(error);
      return {
        customerAddress: { id: null },
      };
    }
  },
  getCustomer: async (customerAccessToken: string) => {
    try {
      const {
        data: { customer },
      } = await client.query({
        query: GET_CUSTOMER,
        variables: {
          customerAccessToken,
        },
        fetchPolicy: "no-cache",
      });
      return customer;
    } catch (error) {
      console.log(error);
    }
  },
  updateAddress: async (customerAccessToken, id, address) => {
    try {
      const {
        data: { customerAddressUpdate },
      } = await client.mutate({
        mutation: UPDATE_ADDRESS,
        variables: {
          customerAccessToken,
          id,
          address,
        },
      });
      return customerAddressUpdate;
    } catch (error) {
      console.log(error);
      return {
        customerAddress: { id: null },
      };
    }
  },
  deleteAddress: async (customerAccessToken: string, id: string) => {
    try {
      const {
        data: { customerAddressDelete },
      } = await client.mutate({
        mutation: DELETE_ADDRESS,
        variables: {
          customerAccessToken,
          id,
        },
      });
      return customerAddressDelete;
    } catch (error) {
      console.log(error);
      return {
        deletedCustomerAddressId: null,
      };
    }
  },
  updateDefaultAddress: async (
    customerAccessToken: string,
    addressId: string
  ) => {
    try {
      const {
        data: { customerDefaultAddressUpdate },
      } = await client.mutate({
        mutation: UPDATE_DEFAULT_ADDRESS,
        variables: {
          customerAccessToken,
          addressId,
        },
      });
      return customerDefaultAddressUpdate;
    } catch (error) {
      console.log(error);
      return {
        customer: { defaultAddress: { id: null } },
      };
    }
  },
  customerAccessTokenDelete: async (customerAccessToken: string) => {
    try {
      const {
        data: { customerAccessTokenDelete },
      } = await client.mutate({
        mutation: LOGOUT,
        variables: {
          customerAccessToken,
        },
      });

      return customerAccessTokenDelete;
    } catch (error) {
      console.log(error);
      return {
        customer: { defaultAddress: { id: null } },
      };
    }
  },
  getMultipassLoginUrl: async (
    customerAccessToken: string,
    returnTo: string
  ) => {
    try {
      const {
        data: { url },
      } = await axios.post(
        "https://rzmg14j8he.execute-api.us-west-2.amazonaws.com/beyondmeat/shopify/multipass/login",
        {
          env:
            process.env.GATSBY_ENVIRONMENT === "production" ||
            process.env.GATSBY_ENVIRONMENT === "stage"
              ? "prod"
              : "dev",
          accessToken: customerAccessToken,
          returnTo,
        }
      );

      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
