export const ROUTES = {
  HOME: "/",
  SELL: "/sell",
  SHOP: "/shop",
  BUY: "/buy",
  INGREDIENTS: "/about/our-ingredients",
  NEWSROOM: "/newsroom",
  ARTICLES: "/articles",
  ARTICLE_DETAIL: "/article-detail",
  PRESS_RELEASE: "/press",
  BLOG: "/whats-new",
  PRESS_RELEASES: "/press",
  RECIPES: "/recipes",
  RECIPES_SEARCH: "/recipes-search",
  PRODUCT_OVERVIEW: "/products",
  MISSION: "/mission",
  SELL_DETAIL: "/food-service",
  RECIPE_DETAIL: "/recipes",
  PRODUCT_DETAIL: "/products",
  WHERE_TO_FIND: "/where-to-find",
  CONTACT_US: "/contact",
  ADDRESSES: "/account/addresses",
  NEW_ADDRESS: "/account/addresses/new",
  EDIT_ADDRESS: "/account/addresses/edit",
  LOGIN: "/account/login",
  REGISTER: "/account/register",
  ORDER: "/account/order",
  ACCOUNT: "/account",
  CART: "/cart",
  FAQS: "/faqs",
  PRODUCT_INQUIRIES: "/contact/product-inquiries",
  FOOD_SERVICE: "/contact/food-service",
  INTERNATIONAL_SALES: "/contact/international-sales",
  ASSETS_IMAGES: "/newsroom/images",
  ASSETS_VIDEOS: "/newsroom/videos",
  ASSETS_FACT: "/newsroom/fact-sheets",
};
