/**
 * Temporary wrapper over gatsby-plugin-intl
 */
import { useMemo } from "react";

export const useIntl = (): {
  locale: string;
} =>{
  const lang = useMemo(() => {
    return {
      locale: process.env.GATSBY_ACTIVE_LOCALE || ""
    };
  }, []);
  return lang;
};

export default useIntl;
