import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { useIntl } from "./useIntl";
import { camelCase, mapKeys } from "lodash";
import { useLabelsStaticQuery } from "./staticQueries/useLabelsStaticQuery";

type Labels = { [label: string]: string };

export const useStaticLabels = (): Labels => {
  const intl = useIntl();
  const data = useLabelsStaticQuery();

  const labels = useMemo<Labels>((): Labels => {
    const translatedData = getTranslations(
      data,
      "directus.labels.translations",
      intl.locale
    );

    const formatted = mapKeys(translatedData, (_v, k) => camelCase(k));

    return formatted;
  }, [data, intl]);

  return labels;
};
