import React, { ReactElement, useMemo, memo, useCallback } from "react";
import styled from "styled-components";
import currencyFormatter from "currency-formatter";
import ArrowIcon from "../../assets/images/icon_arrow.svg";
import { toREM, vwDesktop, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { useRecommendedProducts } from "../../hooks/useRecommendedProducts";
import { Button } from "../shared/Button";
import { useShopifyProduct } from "../../hooks/useShopifyProduct";
import { Product, Variant } from "../../services/shopify/types";
import { useShopifyCheckout } from "../../hooks/useShopifyCheckout";
import { getLink } from "../../helpers/link";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export const RecommendedProducts = (): ReactElement | null => {
  const labels = useStaticLabels();
  const { addLineItems } = useShopifyCheckout();
  const recommendedProducts = useRecommendedProducts();

  const recommendedCMSProduct = useMemo(() => {
    return recommendedProducts ? recommendedProducts[0] : null;
  }, [recommendedProducts && recommendedProducts[0]?.id]);

  const recommendedShopifyProduct: Product | null = useShopifyProduct(
    recommendedCMSProduct?.variant?.shopifyVariant?.productNumberId
  );

  const recommendedShopifyVariant: Variant | null | undefined =
    React.useMemo(() => {
      try {
        return recommendedShopifyProduct?.variants?.find(
          (v) =>
            v.sku === recommendedCMSProduct?.variant?.shopifyVariant?.variantSKU
        );
      } catch (e) {
        return null;
      }
    }, [recommendedShopifyProduct, recommendedCMSProduct]);

  const handleAddToCart = useCallback(async () => {
    if (recommendedShopifyVariant?.id) {
      addLineItems([
        {
          variantId: recommendedShopifyVariant?.id,
          quantity: 1,
        },
      ]);
    }
  }, [recommendedShopifyVariant, addLineItems]);

  if (!recommendedCMSProduct) {
    return null;
  }

  return (
    <Container>
      <Title>{labels?.moreTastyItems}</Title>
      <RecommendedProduct>
        <Info>
          <ProductImage
            alt={recommendedCMSProduct?.variant?.image?.alt}
            src={recommendedCMSProduct?.variant?.image?.src}
          />
          <div>
            <ProductName>{recommendedCMSProduct?.variant?.title}</ProductName>
            <ProductPrice>
              {currencyFormatter.format(
                Number(recommendedShopifyVariant?.priceV2?.amount),
                {
                  code: recommendedShopifyVariant?.priceV2?.currencyCode,
                }
              )}
            </ProductPrice>
          </div>
        </Info>
        <Actions>
          <StyledButton onClick={handleAddToCart} label={"Add to Cart"} />
          <Link href={getLink(recommendedCMSProduct?.variant?.link)}>
            <span>{labels?.view}</span> <Icon src={ArrowIcon} alt={"View"} />
          </Link>
        </Actions>
      </RecommendedProduct>
    </Container>
  );
};

const Title = styled.p`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #ffffff;
  font-size: ${toREM(24)}rem;
  line-height: ${toREM(28)}rem;
  letter-spacing: 0.06em;
  font-weight: 900;
  text-transform: uppercase;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(24)};
  border-top: 2px solid #2b6447;
  padding: ${vwMobile(46)} ${vwMobile(0)} ${vwMobile(24)};
  margin-top: ${vwMobile(28)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${vwDesktop(28)};
    gap: ${vwDesktop(24)};
    padding: ${vwDesktop(40)} ${vwDesktop(0)} ${vwDesktop(24)};
  }
`;

const RecommendedProduct = styled.div`
  background-color: #033305;
  padding: ${vwMobile(32)};
  display: flex;
  flex-wrap: wrap;
  gap: ${vwMobile(23)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(23)};
    padding: ${vwDesktop(27)} ${vwDesktop(17)};
  }
`;

const Info = styled.div`
  display: flex;
  gap: ${vwMobile(23)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(23)};
    & > div {
      width: ${vwDesktop(140)};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};
  width: 100%;
  align-items: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(16)};
    width: fit-content;
    align-items: flex-end;
  }
`;

const ProductImage = styled.img`
  height: auto;
  width: ${vwMobile(120)};
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(97)};
  }
`;

const ProductName = styled.p`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  font-weight: 900;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const ProductPrice = styled(ProductName)`
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  padding: ${vwMobile(18)};
  min-width: 100%;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(10)};
    min-width: fit-content;
  }
`;

const Link = styled.a`
  color: #75c154;
  cursor: pointer;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  line-height: ${toREM(21)}rem;
  text-decoration: none;
  font-weight: 800;
  letter-spacing: 0.01em;
  display: flex;
  align-content: center;
  gap: ${vwMobile(7)};
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(7)};
  }
`;

const Icon = styled.img`
  width: ${vwMobile(24)};
  height: ${vwMobile(24)};
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(24)};
    height: ${vwDesktop(24)};
  }
`;

export default memo(RecommendedProducts);
