import React from "react";
import validator from "validator";
import styled from "styled-components";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getLink } from "../../../helpers/link";

export type LinkProps = {
  to: string;
  children: React.ReactNode | null | undefined;
  className?: string;
  anchor?: boolean;
  blank?: boolean;
  withBorder?: boolean;
};

const Link = ({ to, children, anchor, blank = false, ...props }: LinkProps) => {
  const shouldForceBlank = () => {
    if (!to || typeof to !== "string") {
      return false;
    }
    try {
      return validator.isURL(to) && to.startsWith("http");
    } catch (error) {
      return false;
    }
  };

  const link = getLink(to);

  if (!link) {
    return <StyledDiv {...props}>{children}</StyledDiv>;
  }

  return (
    <StyledLink
      href={link}
      target={shouldForceBlank() || blank ? "_blank" : "_self"}
      rel={"noreferrer"}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  cursor: pointer;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
`;

const StyledDiv = styled.div``;

export default Link;
