
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      header {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          id
          languages_code {
            name
            code
          }
          status
          menu_items {
            header_menu_item_id {
              translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                languages_code {
                  name
                  code
                }
                link
                label
                sub_menu_items {
                  header_sub_menu_item_id {
                    translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                      languages_code {
                        name
                        code
                      }
                      link
                      label
                      options {
                        header_item_group_id {
                          translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                            languages_code {
                              name
                              code
                            }
                            name
                            items {
                              header_item_id {
                                translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                                  languages_code {
                                    name
                                    code
                                  }
                                  primary_button_link
                                  secondary_button_link
                                  menu_item_link
                                  name
                                  image_alt
                                  image {
                                    id
                                    filename_disk
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

  export const useHeaderStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  