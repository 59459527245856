
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      footer {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          languages_code {
            name
            code
          }
          status
          sections
          copyright
          links
          social_title
          instagram_url
          threads_url
          youtube_url
          facebook_url
          twitter_url
          linkedin_url
        }
      }
    }
  }
`;

  export const useFooterStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  