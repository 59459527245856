/**
 * Temporary wrapper over gatsby-plugin-intl
 */
import { useMemo } from "react";
import { useIntl } from "./useIntl";
import { rtlLocales } from "../helpers/locales";

export const useRightToLeft = (): boolean => {
  const lang = useIntl();

  const isRTL = useMemo(()=>{
    return rtlLocales.includes(lang?.locale);
  }, [lang?.locale]);

  return isRTL;
};

export default useRightToLeft;
