import { useIntl } from "./useIntl";
import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { FooterData } from "../components/Footer/Footer";
import { isPublished } from "../helpers/directus";
import { useFooterStaticQuery } from "../hooks/staticQueries/useFooterStaticQuery";

export const useFooterData = (): FooterData => {
  const intl = useIntl();
  const data = useFooterStaticQuery();

  const footerData = useMemo<FooterData>((): FooterData => {
    const translatedData =
      getTranslations(data, "directus.footer.translations", intl.locale) ?? {};

    return {
      isPublished: isPublished(translatedData?.status),
      social: {
        social_title: translatedData?.social_title,
        instagram_url: translatedData?.instagram_url,
        youtube_url: translatedData?.youtube_url,
        facebook_url: translatedData?.facebook_url,
        threads_url: translatedData?.threads_url,
        twitter_url: translatedData?.twitter_url,
        linkedin_url: translatedData?.linkedin_url,
      },
      sections: translatedData?.sections,
      sub_footer: {
        copyright: translatedData?.copyright,
        links: translatedData?.links?.map((l) => {
          return {
            label: l?.link_label,
            url: l?.link_url,
            isCookie: l?.is_cookies,
          };
        }),
      },
    };
  }, [data, intl]);

  return footerData;
};
