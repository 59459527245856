export enum Viewports {
  mobile = "mobile",
  desktop = "desktop",
}

export const getWindow = () => {
  return typeof window !== "undefined"
    ? window
    : {
        innerWidth: 0,
      };
};

export const MOBILE_WIDTH = 375;

export const DESKTOP_WIDTH = 1440;

export const toVW = (valueInPixels: number, viewport: Viewports): number => {
  if (!valueInPixels) {
    return 0;
  }
  if (viewport === Viewports.desktop) {
    return (valueInPixels / DESKTOP_WIDTH) * 100;
  }
  return (valueInPixels / MOBILE_WIDTH) * 100;
};

export const toVWMobile = (valueInPixels: number): number =>
  toVW(valueInPixels, Viewports.mobile);

export const vwMobile = (valueInPixels: number): string =>
  toVWMobile(valueInPixels) + "vw";

export const toVWDesktop = (valueInPixels: number): number =>
  toVW(valueInPixels, Viewports.desktop);

export const vwDesktop = (valueInPixels: number): string =>
  toVWDesktop(valueInPixels) + "vw";

export const toPixelsFromVW = (valueInVW: number): number => {
  const window = getWindow();
  return (valueInVW * window?.innerWidth) / 100;
};

export const toREM = (valueInPixels: number): number => valueInPixels / 16;

export const rem = (valueInPixels: number): string =>
  toREM(valueInPixels) + "rem";
