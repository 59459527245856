/**
 * Types that are shared across the Header components
 */

export type Item = {
  image: string;
  imageAlt: string;
  name?: string;
  primaryButtonLabel?: string;
  primaryButtonLink?: string;
  secondaryButtonLabel?: string;
  secondaryButtonLink?: string;
  secondaryButtonIcon?: string;
};

export type ItemsGroup = {
  name: string;
  items: Array<Item>;
};

export type SubMenuItemWithOptions = {
  label: string;
  options: Array<ItemsGroup>;
};

export type SubMenuItemWithLink = {
  label: string;
  link: string;
};

export type SubMenuItem = SubMenuItemWithOptions | SubMenuItemWithLink;

export type MenuItemWithSubItems = {
  label: string;
  subMenuItems: Array<SubMenuItem>;
};

export type MenuItemWithLink = {
  label: string;
  link: string;
};

export type MenuItem = MenuItemWithSubItems | MenuItemWithLink;

export enum NavbarOptions {
  login = "login",
  search = "search",
  cart = "cart",
  empty = "",
}
