import axios from "axios";

type ContactSalesFormPayload = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  businessName: string;
  products: string;
  distributors: string;
  city: string;
  state: string;
  country: {
    name?: string;
    code: string;
  };
  agreement: boolean;
};

type ProductInquiryFormPayload = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  state: string;
  country: {
    name?: string;
    code: string;
  };
  store: string;
  lotCode: string;
  orderNumber: string;
  subject: string;
  message: string;
};

interface KlaviyoServiceInterface {
  subscribeMailingList: (
    listId: string,
    payload: { email: string }
  ) => Promise<void>;

  contactSales: (
    listId: string,
    payload: ContactSalesFormPayload,
    form: string
  ) => Promise<void>;

  contactSalesFoodService: (
    listId: string,
    payload: ContactSalesFormPayload
  ) => Promise<void>;

  contactSalesInternational: (
    listId: string,
    payload: ContactSalesFormPayload
  ) => Promise<void>;

  contactSalesUSCanada: (
    listId: string,
    payload: ContactSalesFormPayload
  ) => Promise<void>;

  productInquiry: (
    listId: string,
    payload: ProductInquiryFormPayload
  ) => Promise<void>;

  submitDynamicForm: (
    config: { listId: string; formName: string },
    payload: any
  ) => Promise<void>;
}

const KLAVIYO_FIXED_FIELDS: { [key: string]: string } = {
  first_name: "$first_name",
  last_name: "$last_name",
  phone_number: "$phone_number",
  organization: "$organization",
  city: "$city",
  region: "$region",
  country: "$country",
  source: "$source",
  consent: "$consent",
};

export const KlaviyoService: KlaviyoServiceInterface = {
  subscribeMailingList: async (listId, { email }) => {
    const params = new URLSearchParams();
    params.append("g", listId);
    params.append("email", email);
    params.append("$fields", "$source,$consent");
    params.append("$source", "website");
    params.append("$consent", "true");
    await axios.post(
      "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      params
    );
  },
  contactSales: async (
    listId,
    {
      email,
      distributors,
      products,
      state,
      city,
      businessName,
      firstName,
      lastName,
      country,
      phoneNumber,
      agreement,
    },
    form
  ) => {
    const getSellLocation = () => {
      if (!country?.code) {
        return null;
      }
      if (country?.code?.toLowerCase() === "us") {
        return "us";
      }
      if (country?.code?.toLowerCase() === "ca") {
        return "canada";
      }
      return "international";
    };
    const params = new URLSearchParams();
    params.append("g", listId);
    params.append("email", email);
    params.append(
      "$fields",
      "$first_name,$last_name,$phone_number,$organization,$city,$region,$country,country_name,products_interested_in,distributors_purchased_from,sell_request,sell_location,$source,form,$consent"
    );
    params.append("$first_name", firstName);
    params.append("$last_name", lastName);
    params.append("$phone_number", phoneNumber);
    params.append("$organization", businessName);
    if (city) {
      params.append("$city", city);
    }
    if (state) {
      params.append("$region", state);
    }
    params.append("$country", country?.code);
    params.append("country_name", country?.name || "");
    params.append("products_interested_in", products);
    params.append("distributors_purchased_from", distributors);
    params.append("$source", "website");
    params.append("sell_request", "true");
    params.append("form", form);
    if (agreement) {
      params.append("$consent", "true");
    }
    const sellLocation = getSellLocation();
    if (sellLocation) {
      params.append("sell_location", sellLocation);
    }

    await axios.post(
      "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      params
    );
  },
  contactSalesFoodService: async (listId, payload) => {
    return KlaviyoService.contactSales(listId, payload, "food_service");
  },
  contactSalesUSCanada: async (listId, payload) => {
    return KlaviyoService.contactSales(listId, payload, "us_canada");
  },
  contactSalesInternational: async (listId, payload) => {
    return KlaviyoService.contactSales(listId, payload, "international");
  },

  productInquiry: async (
    listId,
    {
      email,
      state,
      city,
      firstName,
      lastName,
      country,
      phoneNumber,
      store,
      lotCode,
      message,
      orderNumber,
      subject,
    }
  ) => {
    const params = new URLSearchParams();
    params.append("g", listId);
    params.append("email", email);
    params.append(
      "$fields",
      "$first_name,$last_name,$phone_number,$organization,$city,$region,$country,country_name,store,lot_code,message,order_number,subject,$source,form"
    );
    params.append("$first_name", firstName);
    params.append("$last_name", lastName);
    params.append("$phone_number", phoneNumber);
    params.append("$city", city);
    params.append("$region", state);
    params.append("$country", country?.code);
    params.append("country_name", country?.name || "");
    params.append("store", store);
    params.append("lot_code", lotCode);
    params.append("message", message);
    params.append("order_number", orderNumber);
    params.append("subject", subject);
    params.append("$source", "website");
    params.append("form", "product_inquiry");
    await axios.post(
      "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      params
    );
  },

  submitDynamicForm: async (config, payload) => {
    const { listId, formName } = config;
    const params = new URLSearchParams();
    params.append("g", listId);
    params.append("form", formName);
    const fields: string[] = ["form"];

    Object.entries(payload)?.forEach(([key, { value }]) => {
      const fixedField = KLAVIYO_FIXED_FIELDS[key];
      params.append(fixedField || key, value as string);
      fields.push(fixedField || key);
    });

    params.append("$fields", fields.join(","));
    await axios.post(
      "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      params
    );
  },
};
