import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFontFamily } from "../store/fontFamily";
import { useIntl } from "./useIntl";
import { useFontFamilyStaticQuery } from "./staticQueries/useFontFamilyStaticQuery";
import useAppSelector from "./useAppSelector";

export const useFontFamily = (): string => {
  const intl = useIntl();
  const data = useFontFamilyStaticQuery();
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fontFamilyLocale = data?.directus?.languages.filter(language => language.code === intl.locale);
    dispatch(setFontFamily(fontFamilyLocale[0]?.font_family));
  }, [data?.directus?.languages.length, intl?.locale]);

  const font = useAppSelector((state) => state.styles.fontFamily);

  return font;
}
