const isPreview = (): boolean =>
  process.env.GATSBY_PREVIEW_RELEASE === true ||
  process.env.GATSBY_PREVIEW_RELEASE === "true";

enum DIRECTUS_STATUS {
  PUBLISHED = "published",
  ARCHIVED = "archived",
  DRAFT = "draft",
}

export const isPublished = (status: string): boolean => {
  if (isPreview()) {
    return status !== DIRECTUS_STATUS.ARCHIVED;
  }
  return status === DIRECTUS_STATUS.PUBLISHED;
};
